import React from 'react';
import { Box, Heading } from '@chakra-ui/react';

function CTAH1({ text, mb, pb }) {
  let colors = '';

  if (text === 'Benar') {
    colors = '#00BB00';
  } else if (text === 'Belum tepat') {
    colors = '#FC0C0C';
  } else {
    colors = 'black';
  }

  return (
    <Box
      mb={mb}
      pb={pb}
      w="100%"
    >
      <Heading
        as="h1"
        fontSize={{
          lg: '22px',
          md: '22px',
          base: '16px'
        }}
        fontFamily="montserrat"
        fontWeight="bold"
        color={colors}
        textAlign="center"
      >
        { text }
      </Heading>
    </Box>
  );
}

export default CTAH1;
