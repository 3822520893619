export const storeAnswer = (data) => {
  let answers = [];
  if (localStorage.getItem('user_answers')) {
    answers = JSON.parse(localStorage.getItem('user_answers')).filter(
      (answer) => answer.question_id !== data.question_id
    );
  }
  answers.push(data);
  localStorage.setItem('user_answers', JSON.stringify(answers));
  return answers;
};
