import React, { useEffect, useState } from 'react';
import {
  Box,
  Center,
  Divider,
  Flex,
  Heading,
  Text,
  CircularProgress,
  CircularProgressLabel,
  Image,
  Container
} from '@chakra-ui/react';
import { customBackground } from 'style/theme';
import ResultCarousel from 'components/Result/ResultCarousel';
import Navbar from 'components/common/Navbar';
// import ButtonSecondary from 'components/Button/ButtonSecondary';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { insertResultData, setSendEmail } from 'reducer/finalResultReducer';
import { setUserId } from 'reducer/userReducer';
import axios from 'axios';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import posthog from 'posthog-js';

function FinalResultPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = useSelector((state) =>
    state.user.userId);
  const result = useSelector((state) => state.finalResult.data);
  const [isLoading, setIsLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const disableNext = useSelector((state) =>
    state.question.disableNext);

  useEffect(() => {
    if (localStorage.getItem('user_id') !== '' && (userId === 0 || userId === '0')) {
      dispatch(setUserId(localStorage.getItem('user_id')));
    }
    customBackground('#008C811A');
    return () => {
      document.body.style = '';
    };
  }, []);

  async function sendEmailResult() {
    dispatch(setSendEmail());
    await axios
      .post(`${process.env.REACT_APP_API}send-mail-result`, {
        id: userId
      })
      .then(() => {
        // console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getResultData() {
    await axios
      .get(`${process.env.REACT_APP_API}result?user=${userId}`, {
        onDownloadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
        }
      })
      .then((res) => {
        dispatch(insertResultData(res.data.result));
        if (res.data.result.email !== 'null@gmail.com' && res.data.result.is_send === 0) { sendEmailResult(); }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    // console.log(sendEmail);
    if (userId !== 0 || userId !== '0') {
      getResultData();
    }
  }, [userId]);

  useEffect(() => {
    if (progress === 100) {
      setIsLoading(false);
    }
  }, [progress]);

  posthog.capture('NAMA Result', {
    page: 'Result Page'
  });

  if (isLoading) {
    return (
      <Center h="100vh">
        <Container>
          <Box textAlign="center">
            <CircularProgress value={progress} color="dietela.primary" size="70px">
              <CircularProgressLabel>
                {progress}
                &nbsp;%
              </CircularProgressLabel>
            </CircularProgress>
            <Image
              src="https://ik.imagekit.io/dietela/loading-result.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1672633056824"
              minHeight="100px"
              w={{
                lg: '300px',
                md: '300px',
                base: '250px'
              }}
              mt={12}
              mb={12}
              mx="auto"
            />
            <Text
              fontSize={{
                base: '14px',
                md: '16px',
                lg: '16px'
              }}
              fontWeight="400"
              fontFamily="roboto"
            >
              Menilai kondisi gizi dan mendesain saran gizi untukmu…
            </Text>
          </Box>
        </Container>
      </Center>
    );
  }

  return (
    <Box>
      <Box
        px={{
          base: '4',
          md: '10',
          lg: '20'
        }}
      >
        <Navbar />
        <Divider borderColor="gray.300" />
      </Box>
      <Flex flexDirection="column" my={2} alignItems="center">
        <Heading fontSize="2xl" color="dietela.primary">
          {result.username}
        </Heading>
        <Text color="text.brown" fontSize="lg" fontWeight="semibold">
          {result.gender}
        </Text>
        <Text color="text.brown">
          {result.age}
          &nbsp;Tahun
        </Text>
      </Flex>
      <Box
        px={{
          base: '4',
          md: '0'
        }}
      >
        <ResultCarousel />
      </Box>
      {disableNext ? '' : (
        <Flex
          as="button"
          position="fixed"
          // top="calc(100vh - 49px)"
          bottom="0"
          h="50px"
          w="100%"
          bg="dietela.primary"
          zIndex="999"
          alignItems="center"
          justifyContent="center"
          onClick={() => navigate('/program-recommendation')}
          color="white"
        >
          <Text
            fontWeight="600"
          >
            SOLUSI UNTUKMU
          </Text>
          <ArrowForwardIcon ml="8px" />
        </Flex>
      )}
    </Box>
  );
}

export default FinalResultPage;
