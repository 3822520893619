import React from 'react';
import {
  Flex,
  Grid,
  GridItem,
  Image,
  Text
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import ResultCardLayout from 'components/layout/ResultCardLayout';

function NutritionStatusCard() {
  const result = useSelector((state) => state.finalResult.data);
  function handleBg(index) {
    switch (index) {
      case 0:
        return 'dietela.primary';
      case 1:
        return '#E6A603';
      case 2:
        return '#F57559';
      case 3:
        return '#4B73A1';
      case 4:
        return '#64A898';
      default:
        return 'dietela.primary';
    }
  }
  return (
    <ResultCardLayout>
      {result.conditional.map(
        (slide) =>
          slide.card === 'Slide 3' && (
            <Flex
              flexDirection="column"
              key={slide.card}
              justifyContent="start"
              alignItems="center"
              w="100%"
            >
              <Image
                src={slide.image}
                alt={slide.title}
                h="87px"
                w="100%"
                objectFit="cover"
              />
              <Flex
                flexDirection="column"
                p={8}
                gap={5}
                flex="1"
                justifyContent="center"
              >
                <Flex flexDir="column" gap="2">
                  <Text
                    fontSize="lg"
                    textAlign={{
                      base: 'left',
                      md: 'center'
                    }}
                  >
                    {slide.clientName}
                  </Text>
                  {slide.description.split('|').map(
                    (text) => (
                      <Text
                        key={text}
                        fontWeight="bold"
                        textAlign={{
                          base: 'left',
                          md: 'center'
                        }}
                        fontSize={{
                          md: '18px'
                        }}
                      >
                        {text}
                      </Text>
                    )
                  )}
                </Flex>
                <Grid
                  templateRows={{
                    base: 'repeat(2, 1fr)',
                    md: 'repeat(2, 1fr)'
                  }}
                  templateColumns={{
                    base: 'repeat(2, 1fr)',
                    md: 'repeat(6, 1fr)'
                  }}
                  gap={2}
                  w="100%"
                >
                  {slide.status.map((status, idx) => (
                    <GridItem
                      key={status.label || status.label1}
                      bg={handleBg(idx)}
                      color="white"
                      px={5}
                      py={2}
                      borderRadius="xl"
                      textAlign="center"
                      colSpan={{
                        base: idx === slide.status.length - 1 ? 2 : null,
                        md: idx === 0 || idx === 1 || idx === 2 ? 2 : 3
                      }}
                    >
                      {/* {console.log(status)} */}
                      <Flex h="100%" justifyContent="center" flexDir="column">
                        <Text fontSize="sm">{status?.label1}</Text>
                        <Text fontSize="xl" fontWeight="bold">
                          {status.value}
                          {' '}
                          {idx === 1 ? 'Kg' : '%'}
                        </Text>
                        <Text fontSize="sm">{status.label}</Text>
                        <Text fontSize="sm">{status?.label2}</Text>
                      </Flex>
                    </GridItem>
                  ))}
                </Grid>
              </Flex>
            </Flex>
          )
      )}
    </ResultCardLayout>
  );
}

export default NutritionStatusCard;
