import React, { useEffect, useState } from 'react';
import { Box, Flex, RadioGroup, Text, Radio, Stack, Center, Spacer } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { setDisableNext, setChoiceGroup, setChoice, setAnswers } from 'reducer/questionReducer';
import { storeAnswer } from 'utils/localStorage';

function RadioGroupInput({ data }) {
  const [value, setValue] = useState('');
  const dispatch = useDispatch();
  // const currentQuestion = useSelector((state) =>
  //   state.question.data);

  const choiceGroup = useSelector((state) =>
    state.question.choiceGroup);

  const handleChange = (item) => {
    dispatch(setChoiceGroup({
      ...choiceGroup,
      [item.question_id]: {
        question_type: 'radio_group',
        userChoice: item.id,
        choiceScore: item.score,
        option: item.option,
        questionId: item.question_id
      }
    }));
  };

  useEffect(() => {
    if (choiceGroup.length !== 0) {
      setValue(Object.keys(choiceGroup));
      const arr = Object.keys(choiceGroup);
      let scoreEach = 0;
      for (let index = 0; index < arr.length; index += 1) {
        const element = arr[index];
        scoreEach += choiceGroup[element].choiceScore;

        // save answers to local storage
        const answerList = storeAnswer({
          question_id: parseInt(element, 10),
          question_type: 'radio_group',
          answer: choiceGroup[element].option,
          choiceGroup
        });
        dispatch(setAnswers(answerList));
        if (data.data.data) {
          let radioGroupId = 'radio-group-2';
          if (data.data.data[0].Options.length === 3) {
            radioGroupId = 'radio-group-1';
          }
          // save answers to local storage
          const answerList = storeAnswer({
            question_id: radioGroupId,
            question_type: 'radio_group',
            choiceGroup
          });
          dispatch(setAnswers(answerList));
        }
      }
      const score = scoreEach;
      dispatch(setChoice({
        choiceScore: score
      }));
    }
  }, [choiceGroup]);

  useEffect(() => {
    let radioGroupId;
    if (data.data.data) {
      radioGroupId = 'radio-group-2';
      if (data.data.data[0].Options.length === 3) {
        radioGroupId = 'radio-group-1';
      }
    }

    if (localStorage.getItem('user_answers')) {
      if (JSON.parse(localStorage.getItem('user_answers')).some((answer) => answer.question_id === radioGroupId)) {
        dispatch(setChoiceGroup(
          JSON.parse(localStorage.getItem('user_answers')).filter((answer) => answer.question_id === radioGroupId)[0].choiceGroup
        ));
      }
    }
  }, []);

  useEffect(() => {
    if (value.length < 4) {
      dispatch(setDisableNext(true));
    } else {
      dispatch(setDisableNext(false));
    }
  }, [value]);

  // const headingText = data.data.option.split('|');
  // const options = data.data.data[0].Options;
  // console.log(data.data);
  const options = data.data.options.split('|');
  return (
    <Center>
      <Stack
        direction="column"
      >
        <Box
          as="h1"
          fontFamily={{
            lg: '16px',
            md: '16px',
            base: '12px'
          }}
          fontWeight="600"
        >
          { data.data.header }
        </Box>
        <Box
          display="Flex"
        >
          <Spacer />
          {options.map((item) => (
            <Text
              w={{
                base: '35px',
                md: '60px',
                lg: '60px'
              }}
              mx="5px"
              fontSize={{
                lg: '12px',
                md: '12px',
                base: '10px'
              }}
              align="center"
            >
              {item}
            </Text>
          ))}
        </Box>
        {data.data.data.map((item, index) => {
          // Set Selected Radio Option Answered from Local Storage
          let defaultOptionValue;
          if (localStorage.getItem('user_answers')) {
            if (JSON.parse(localStorage.getItem('user_answers')).some((answer) => answer.question_id === item.id)) {
              const savedAnswer = JSON.parse(localStorage.getItem('user_answers')).filter((answer) => answer.question_id === item.id)[0];
              defaultOptionValue = savedAnswer.answer;
            }
          }
          return (
            <Box
              key={item.id}
              borderRadius={3}
              cursor="pointer"
              color="white"
              bg={
                index % 2 === 0 ? 'dietela.primary' : 'dietela.gray'
              }
              align="center"
              py="8px"
              paddingLeft="15px"
              paddingRight="0px"
            >
              <Flex
                justifyContent="start"
                alignItems="center"
              >
                <Text
                  as="h1"
                  align="start"
                  fontSize={{
                    lg: '16px',
                    md: '16px',
                    base: '12px'
                  }}
                  fontWeight="600"
                  fontFamily="montserrat"
                  mr={6}
                >
                  {item.question}
                </Text>
                <Spacer />
                <RadioGroup
                  colorScheme="dietela.gray"
                  name={item.id}
                  defaultValue={defaultOptionValue}
                >
                  <Stack direction="row">
                    {item.Options.map((option) => (
                      <Radio
                        key={option.id}
                        w="100%"
                        px={{
                          lg: '24px',
                          md: '24px',
                          base: '10px'
                        }}
                        py={3}
                        value={option.option}
                        onChange={() => handleChange(option)}
                        // bg="white"
                        // _checked={{
                        //   bg: 'white'
                        // }}
                      />
                    ))}
                  </Stack>
                </RadioGroup>
              </Flex>
            </Box>
          );
        })}
      </Stack>
    </Center>
  );
}

export default RadioGroupInput;
