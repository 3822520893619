import React from 'react';
import {
  Container,
  Button,
  Heading,
  HStack,
  Image,
  Text,
  Center
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FaClock } from 'react-icons/fa';
import posthog from 'posthog-js';

function StartPage() {
  posthog.capture('NAMA Homepage', {
    page: 'Homepage'
  });
  const navigate = useNavigate();
  return (
    <Center h="100vh" bgGradient="linear(to-b, gradient.top, gradient.bottom)">
      <Container
        maxW="xl"
        display="flex"
        flexDir="column"
        justifyContent="center"
        alignItems="center"
      >
        <Image
          src="https://ik.imagekit.io/dietela/Logo_dietela.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1672633056849"
          mb="5rem"
          alt="Logo Dietela"
          width={['50%', 'auto']}
        />
        <Heading
          size={['lg', 'xl']}
          fontWeight="semibold"
          mb="10rem"
          color="text.brown"
        >
          Diet Quiz
        </Heading>
        <HStack mb="1rem">
          <FaClock />
          <Text fontWeight="semibold">Perkiraan waktu : 5 menit</Text>
        </HStack>
        <Button
          onClick={() =>
            navigate('/instruction')}
          fontSize="2xl"
          fontWeight="bold"
          height="50px"
          width="200px"
          borderRadius={['24px', '8px']}
          color="white"
          bg="dietela.primary"
          _active={{
            bg: 'dietela.primary-variant'
          }}
          _hover={{
            bg: 'dietela.primary-variant'
          }}
        >
          Mulai Quiz
        </Button>
      </Container>
    </Center>
  );
}

export default StartPage;
