import { createSlice } from '@reduxjs/toolkit';

export const progressBarSlicer = createSlice({
  name: 'progressBar',
  initialState: {
    part1: 0,
    part2: 0,
    part3: 0,
    part4: 0,
    part5: 0,
    part6: 0,
    part7: 0
  },

  reducers: {
    progressIncrement: (state, action) => {
      const { type, value } = action.payload;

      switch (type) {
        case 'PART1_INCREMENT':
          return {
            ...state,
            part1: state.part1 + value
          };
        case 'PART2_INCREMENT':
          return {
            ...state,
            part2: state.part2 + value
          };
        case 'PART3_INCREMENT':
          return {
            ...state,
            part3: state.part3 + value
          };
        case 'PART4_INCREMENT':
          return {
            ...state,
            part4: state.part4 + value
          };
        case 'PART5_INCREMENT':
          return {
            ...state,
            part5: state.part5 + value
          };
        case 'PART6_INCREMENT':
          return {
            ...state,
            part6: state.part6 + value
          };
        case 'PART7_INCREMENT':
          return {
            ...state,
            part7: state.part7 + value
          };
        default:
          return state;
      }
    },

    progressDecrement: (state, action) => {
      const { type, value } = action.payload;

      switch (type) {
        case 'PART1_DECREMENT':
          return {
            ...state,
            part1: state.part1 - value
          };
        case 'PART2_DECREMENT':
          return {
            ...state,
            part2: state.part2 - value
          };
        case 'PART3_DECREMENT':
          return {
            ...state,
            part3: state.part3 - value
          };
        case 'PART4_DECREMENT':
          return {
            ...state,
            part4: state.part4 - value
          };
        case 'PART5_DECREMENT':
          return {
            ...state,
            part5: state.part5 - value
          };
        case 'PART6_DECREMENT':
          return {
            ...state,
            part6: state.part6 - value
          };
        case 'PART7_DECREMENT':
          return {
            ...state,
            part7: state.part7 - value
          };
        default:
          return state;
      }
    }
  }
});

export const { progressIncrement, progressDecrement } = progressBarSlicer.actions;
export default progressBarSlicer.reducer;
