import React, { useEffect, useState } from 'react';
import {
  Box,
  Center,
  Divider,
  Flex,
  Heading,
  Link,
  Text,
  Button,
  Skeleton,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  useMediaQuery,
  useToast
} from '@chakra-ui/react';
import BaseLayout from 'components/layout/BaseLayout';
import { useDispatch, useSelector } from 'react-redux';
import { setUserId } from 'reducer/userReducer';
import axios from 'axios';
import { customBackground } from 'style/theme';
import posthog from 'posthog-js';
import { Rate } from 'antd';

function ProgramPricingPage() {
  const dispatch = useDispatch();
  const userId = useSelector((state) =>
    state.user.userId);
  const [data, setData] = useState('');
  const [section1, setSection1] = useState('');
  const [section2, setSection2] = useState('');
  const [section3, setSection3] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [rating, setRating] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMobile] = useMediaQuery('(max-width: 480px)');
  const toast = useToast();

  const openModal = () => {
    onOpen();
  };

  const handleRatingChange = (value) => {
    setRating(value);
  };

  async function checkIsRated() {
    await axios
      .get(`${process.env.REACT_APP_API}rating/rated/${userId}`).then((res) => {
        console.log(res.data.data.count);
        if (!res.data.data.count) {
          setTimeout(openModal, 5000);
        } else {
          console.log('rating found:', res.data.data.count);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const closeModal = () => {
    checkIsRated();
    onClose();
  };

  useEffect(() => {
    checkIsRated();
    // setTimeout(openModal, 5000);
    if (localStorage.getItem('user_id') !== '' && (userId === 0 || userId === '0')) {
      dispatch(setUserId(localStorage.getItem('user_id')));
    }
    customBackground('#008C811A');
    return () => {
      document.body.style = '';
    };
  }, []);

  async function getRecommendation() {
    await axios
      .post(`${process.env.REACT_APP_API}recommendations`, {
        id: userId
      })
      .then((res) => {
        setData(res.data);
      });
  }

  async function saveRating() {
    await axios
      .post(`${process.env.REACT_APP_API}rating`, {
        userId,
        rating
      })
      .then((res) => {
        console.log(res.data.message);
        toast({
          title: 'Terima kasih',
          description: res.data.message,
          status: 'success',
          duration: 5000,
          isClosable: true
        });
        onClose();
      });
  }

  async function getLayananDietela() {
    await axios
      .get(`${process.env.REACT_APP_API}layanan-dietela/list`)
      .then((res) => {
        setSection1(res.data.data[0]);
        setSection2(res.data.data[1]);
        setSection3(res.data.data[2]);
        console.log('res.data', res.data);
      });
  }

  async function handleClick(program) {
    // console.log(program);
    await axios.post(`${process.env.REACT_APP_API}cta-click`, {
      id: userId,
      program
    });
  }

  useEffect(() => {
    if (data.data) {
      setIsLoaded(true);
      getLayananDietela();
    }
  }, [data]);

  useEffect(() => {
    // console.log(sendEmail);
    if (userId !== 0 || userId !== '0') {
      getRecommendation();
    }
  }, []);

  useEffect(() => {
    // console.log(sendEmail);
    if (userId !== 0 || userId !== '0') {
      getLayananDietela();
      getRecommendation();
    }
  }, [userId]);

  // console.log(data);
  posthog.capture('NAMA Recommendation Program', {
    page: 'Recommendation Program Page'
  });

  const rateCustomStyle = {
    fontSize: '42px'
  };

  return (
    <BaseLayout>

      <Modal isOpen={isOpen} onClose={onClose} size={isMobile ? 'xs' : 'md'}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton borderRadius="full" onClick={() => closeModal()} />
          <ModalBody>
            <Flex
              mt={16}
              width="100%"
              direction="column"
              align="center"
              justify="center"
              height="100%"
            >
              <Box
                mx={{
                  base: 2,
                  md: 12,
                  lg: 12
                }}
              >
                <Text
                  textAlign="center"
                  fontSize={{
                    base: '16px',
                    md: '18px',
                    lg: '20px'
                  }}
                  fontWeight="600"
                >
                  Bagaimana pengalamanmu menggunakan website kami?
                </Text>
              </Box>
              <Box my={4}>
                <Rate
                  allowHalf
                  defaultValue={0}
                  style={rateCustomStyle}
                  onChange={handleRatingChange}
                />
              </Box>
              <Box my={2}>
                Nilai Rating:&nbsp;
                {rating}
              </Box>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Flex
              width="100%"
              justifyContent="center"
              alignItems="center"
              mb={5}
            >
              <Button
                px={12}
                py={4}
                colorScheme="teal"
                borderRadius="full"
                size={{
                  base: 'md',
                  md: 'lg',
                  lg: 'xl'
                }}
                onClick={() => saveRating()}
              >
                Rating Kami
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Divider borderColor="gray.300" />
      <Skeleton isLoaded={isLoaded}>
        <Flex flexDir="column" my={12} bg="dietela.background-primary-variant" borderRadius="xl">
          <Center flexDir="column" mt={12} gap={8}>
            <Heading fontSize={['24px', '28px']} textAlign="center">
              Program Recommendation
            </Heading>
            <Center
              gap={8}
              flexDir="column"
            >
              <Flex
                border="1px"
                flexDir="column"
                borderRadius="xl"
                borderColor="white"
                justifyContent="space-between"
                bg="#F8EDB4"
                p="10px"
              >
                <Text fontSize="14px" fontWeight="semibold">
                  {data?.data?.header}
                </Text>
              </Flex>
              <Flex p={3} flexDir="column" gap={4}>
                {data?.data?.description.split('|').map(
                  (text) => (
                    <Text textAlign="center" key={text}>
                      {text.includes('~')
                        ? (
                          text.split('~').map((bold, index) =>
                            <Text as="span" key={bold} color={index === 1 ? '#940000' : ''} textAlign="center">{bold}</Text>)
                        ) : (text)}
                    </Text>
                  )
                )}
              </Flex>
              <Flex>
                <Link
                  target="_blank"
                  href={data?.data?.link}
                >
                  <Button
                    borderRadius="full"
                    bg="#F1E456"
                    onClick={() => { handleClick(data?.data?.type); }}
                  >
                    Lebih Lanjut
                  </Button>
                </Link>
              </Flex>
            </Center>
          </Center>
          <Center
            flexDir="column"
            mt={{
              base: 24,
              lg: 12
            }}
            gap={8}
          >
            <Flex w="100%" bg="white" justifyContent="center" pb="2" pt={2}>
              <Center flexDir="column" mt={12} gap={8}>
                {data?.trial === true || data?.trial === 'true'
                  ? (
                    <>
                      <Heading fontSize={['24px', '28px']} textAlign="center">{section1.judul}</Heading>
                      <Text fontSize="14px" fontWeight="bold">
                        {section1.isi}
                      </Text>
                      <Link
                        mb={36}
                        target="_blank"
                        href={section1.link}
                      >
                        <Button
                          borderRadius="full"
                          bg="#F1E456"
                          onClick={() => { handleClick('Free Trial'); }}
                        >
                          {section1.btnText}
                        </Button>
                      </Link>
                    </>
                  ) : (
                    <Box
                      pb="10px"
                      w="100%"
                      justifyContent="center"
                      alignItems="center"
                      display="flex"
                      maxH="fit-content"
                    >
                      <Image
                        src="https://ik.imagekit.io/dietela/svg_webp/result_ProgramRecommendation.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1672636870086"
                        minHeight="100px"
                        w={{
                          lg: '300px',
                          md: '300px',
                          base: '250px'
                        }}
                        overflowY="visible"
                      />
                    </Box>
                  )}
              </Center>
            </Flex>
          </Center>
          <Center flexDir="column" mt={12} gap={4}>
            {data?.trial === true || data?.trial === 'true'
              ? (
                <Image
                  mt={-36}
                  src="https://ik.imagekit.io/dietela/svg_webp/result_ProgramRecommendation.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1672636870086"
                  minHeight="100px"
                  w={{
                    lg: '300px',
                    md: '300px',
                    base: '250px'
                  }}
                  overflowY="visible"
                />
              ) : ('')}
            <Heading fontSize={['24px', '28px']}>{section2.judul}</Heading>
            <Box
              p={6}
              maxW="700px"
              textAlign="center"
            >
              <Flex gap={3} mb={8} flexDir="column">
                <Text>
                  {section2.isi}
                </Text>
              </Flex>
              <Link
                target="_blank"
                href={section2.link}
              >
                <Button
                  borderRadius="full"
                  bg="#F1E456"
                  onClick={() => { handleClick('WA Admin'); }}
                >
                  {section2.btnText}
                </Button>
              </Link>
            </Box>
          </Center>
          <Center flexDir="column" bg="white" mt={12} pt={12} gap={4} borderBottomRadius="xl">
            <Heading fontSize={['24px', '28px']} textAlign="center">{section3.judul}</Heading>
            <Image
              mt={6}
              src="https://ik.imagekit.io/dietela/artikel.webp?updatedAt=1683538521064"
              minHeight="100px"
              w={{
                lg: '300px',
                md: '300px',
                base: '250px'
              }}
              overflowY="visible"
            />
            <Box
              p={6}
              maxW="700px"
              textAlign="center"
            >
              <Flex gap={3} flexDir="column">
                <Text>
                  {section3.isi}
                </Text>
              </Flex>
            </Box>
            <Link
              mb={12}
              target="_blank"
              href={section3.link}
            >
              <Button
                borderRadius="full"
                bg="#F1E456"
                onClick={() => { handleClick('Free Trial'); }}
              >
                {section3.btnText}
              </Button>
            </Link>
          </Center>
        </Flex>
      </Skeleton>
    </BaseLayout>
  );
}

export default ProgramPricingPage;
