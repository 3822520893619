import React from 'react';
import { Flex, Heading, Image, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import ResultCardLayout from 'components/layout/ResultCardLayout';

function DietaryHabitCard() {
  const result = useSelector((state) => state.finalResult.data);
  return (
    <ResultCardLayout>
      {result.conditional.map((slide) =>
        slide.card === 'Slide 5' && (
          <Flex
            key={slide.card}
            flexDirection="column"
            justifyContent="start"
          >
            <Image
              src={slide.image}
              alt={slide.title}
              h="87px"
              w="100%"
              objectFit="cover"
            />
            <Flex
              flexDirection="column"
              justifyContent="center"
              flex="1"
              p={8}
              gap={5}
            >
              <Heading fontSize="xl">{slide.title}</Heading>
              {slide.description.split('|').map(
                (text) =>
                  <Text key={text}>{text}</Text>
              )}
            </Flex>
          </Flex>
        ))}
    </ResultCardLayout>
  );
}

export default DietaryHabitCard;
