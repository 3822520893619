import React from 'react';
import { Button } from '@chakra-ui/react';

function ButtonSecondary({ cta, handleClick, isRounded, disabled }) {
  return (
    <Button
      // px={isLong ? 20 : 10}
      px="20px"
      py="12px"
      bg="dietela.secondary"
      _active={{
        bg: 'dietela.secondary-variant'
      }}
      _hover={{
        bg: 'dietela.secondary-variant'
      }}
      onClick={handleClick}
      borderRadius={isRounded && 'full'}
      w={{
        lg: '215px',
        md: '215px',
        sm: '160px',
        base: '160px'
      }}
      h={{
        lg: '50px',
        md: '50px',
        sm: '30px',
        base: '30px'
      }}
      size={{
        lg: 'md',
        md: 'md',
        sm: 'sm',
        base: 'sm'
      }}
      disabled={disabled}
    >
      {cta}
    </Button>
  );
}

export default ButtonSecondary;
