import React, { useEffect, useState } from 'react';
import { Stack, Checkbox, Flex, Input, Text } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { setChoice, setDisableNext, setAnswers } from 'reducer/questionReducer';
import { storeAnswer } from 'utils/localStorage';

function CheckboxInput({ data }) {
  const dispatch = useDispatch();
  const currentQuestion = useSelector((state) =>
    state.question.data);
  const [disabled, setDisabled] = useState(false);

  const [checked, setChecked] = useState([]);
  const [input, setInput] = useState('');
  const [answer, setAnswer] = useState([]);
  const [score, setScore] = useState(0);

  const handleChangeInput = (event) => {
    event.preventDefault();
    setInput(event.target.value);
    // save answers to local storage
    const answerList = storeAnswer({
      question_id: currentQuestion?.data?.id,
      question_type: currentQuestion?.data?.question_type,
      input,
      answer,
      score,
      checked,
      choiceProps: {
        question_type: 'checkbox',
        userChoice: 0,
        choiceScore: score,
        options: {
          checkbox: answer,
          input: event.target.value
        }
      }
    });
    dispatch(setAnswers(answerList));
  };

  const handleChangeCheckbox = (item) => {
    if (answer.some((option) => option.option_id === item.id)) {
      const newAnswer = answer.filter((option) => option.option_id !== item.id);
      setAnswer(newAnswer);
      setScore(score - item.score);
    } else {
      const newAnswer = {
        option_id: item.id
      };
      setAnswer((prev) => [...prev, newAnswer]);
      setScore(score + item.score);
    }

    setChecked((prev) => {
      if (prev.includes(item.option)) {
        return prev.filter((option) =>
          option !== item.option);
      }
      if (item.option.includes('Belum ada') || item.option.includes('Belum install apapun')) {
        setInput('');
        return [item.option];
      }
      return [...prev, item.option];
    });
  };

  useEffect(() => {
    let choiceProps;
    if (checked.includes('Lainnya') && !input.trim()) dispatch(setDisableNext(true));
    else if (checked.includes('Lainnya') && (input.trim() && input !== '')) {
      console.log('input', input);
      dispatch(setDisableNext(false));
      choiceProps = {
        question_type: 'checkbox',
        userChoice: 0,
        choiceScore: 0,
        options: {
          checkbox: answer,
          input
        }
      };
      dispatch(setChoice(choiceProps));
    }
    // setTimeout(() => {
    // }, 500);
  }, [input]);

  useEffect(() => {
    // Set Selected Radio Option Answered from Local Storage
    if (localStorage.getItem('user_answers')) {
      if (JSON.parse(localStorage.getItem('user_answers')).some(
        (answer) => answer.question_id === currentQuestion?.data?.id
      )) {
        const { input, answer, checked, score, choiceProps } = JSON.parse(localStorage.getItem('user_answers')).filter((answer) => answer.question_id === currentQuestion?.data?.id)[0];
        setInput(input);
        setChecked(checked);
        setAnswer(answer);
        setScore(score);
        dispatch(setChoice(choiceProps));
      }
    }
  }, [currentQuestion]);

  useEffect(() => {
    if (checked.length === 0 || ((checked.includes('Lainnya') && !input))) dispatch(setDisableNext(true));
    else dispatch(setDisableNext(false));
    if (checked.includes('Belum ada') || checked.includes('Belum install apapun')) {
      setDisabled(true);
    } else setDisabled(false);
    setTimeout(() => {
      // save answers to local storage
      const answerList = storeAnswer({
        question_id: currentQuestion?.data?.id,
        question_type: currentQuestion?.data?.question_type,
        input,
        answer,
        score,
        checked,
        choiceProps: {
          question_type: 'checkbox',
          userChoice: 0,
          choiceScore: score,
          options: {
            checkbox: answer,
            input
          }
        }
      });
      dispatch(setAnswers(answerList));
    }, 500);
  }, [checked]);

  useEffect(() => {
    let choiceProps;
    if (answer.length !== 0) {
      console.log('answer', answer);
      console.log('input', input);
      choiceProps = {
        question_type: 'checkbox',
        userChoice: 0,
        choiceScore: 0,
        options: {
          checkbox: answer,
          input
        }
      };
    } else {
      choiceProps = {
        userChoice: 0,
        choiceScore: score,
        options: {
          checkbox: []
        }
      };
    }
    dispatch(setChoice(choiceProps));
    // console.log(answer);
  }, [answer]);

  const parseOption = (text) => {
    const option = text.split('.');
    if (option.length === 1) {
      return option[0];
    }
    return option[1];
  };

  return (
    <Stack direction="column">
      {data.data.Options.map((item) =>
        (
          <Flex
            key={item.id}
            gap={2}
            borderRadius={3}
            flexDir="row"
            cursor="pointer"
            color={
                checked.includes(item.option)
                  ? 'white'
                  : 'black'
              }
            bg={
              checked.includes(item.option)
                ? 'dietela.primary'
                : '#F2F2F2F2'
              }
            _hover={{
              md: {
                color: 'white',
                bg: 'dietela.primary'
              },
              lg: {
                color: 'white',
                bg: 'dietela.primary'
              }
            }}
            boxSizing="border-box"
          >
            {item.option === 'Lainnya' && checked.includes('Lainnya') ? (
              <>
                <Checkbox
                  ps={4}
                  py={4}
                  onChange={() => handleChangeCheckbox(item)}
                  value={item.option}
                  colorScheme="white"
                  isChecked={checked.includes(item.option)}
                  isDisabled={checked.includes(item.option) ? disabled : false}
                />
                <Input
                  mt={1}
                  variant="flushed"
                  textColor="white"
                  borderColor="dietela.primary"
                  placeholder="Type your answer here..."
                  _placeholder={{
                    color: 'white'
                  }}
                  value={input}
                  onChange={handleChangeInput}
                />
              </>
            ) : (
              <Checkbox
                px={4}
                py={3}
                w="100%"
                onChange={() => handleChangeCheckbox(item)}
                value={item.option}
                colorScheme="white"
                isChecked={checked.includes(item.option)}
                isDisabled={checked.includes(item.option) ? false : disabled}
              >
                {parseOption(item.option)}
              </Checkbox>
            )}
          </Flex>
        ))}
      { (checked.includes('Lainnya') && !input.trim()) ? <Text fontWeight="700" color="red">Please type your answer first</Text> : '' }
    </Stack>
  );
}

export default CheckboxInput;
