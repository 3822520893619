import React from 'react';
import {
  Flex,
  Grid,
  GridItem,
  Radio,
  RadioGroup,
  Text
} from '@chakra-ui/react';

function MultipleChoice({ data }) {
  // console.log(data);
  return (
    <>
      <Grid templateColumns="repeat(2, 1fr)">
        <GridItem w="100%" />
        <GridItem w="100%">
          <Flex
            px={{
              base: '0', md: '4'
            }}
            gap={4}
            fontSize="sm"
            alignItems="center"
            justifyContent="space-around"
          >
            <Text>Sering</Text>
            <Text>Terkadang</Text>
            <Text>Tidak Pernah</Text>
          </Flex>
        </GridItem>
      </Grid>
      {data.data.Options.map((item) =>
        (
          <Grid
            key={item.id}
            py={3}
            px={4}
            mt={4}
            color="white"
            borderRadius="md"
            alignItems="center"
            bg={item.id % 2 ? 'dietela.primary' : '#646161'}
            templateColumns="repeat(2, 1fr)"
          >
            {/* {console.log(item)} */}
            <GridItem w="100%">{item.option}</GridItem>
            <GridItem w="100%">
              <RadioGroup display="flex" justifyContent="space-around" colorScheme="white">
                {item.option.option_branch.map((item, index) =>
                  (
                    <Radio key={item.value} value={(index + 1).toString()} />
                  ))}
              </RadioGroup>
            </GridItem>
          </Grid>
        ))}
    </>
  );
}

export default MultipleChoice;
