import React from 'react';
import { Box, Flex, Text, ListItem, UnorderedList } from '@chakra-ui/react';

function CTATable({ tableData, mb }) {
  // console.log(listData);
  const tableDataList = tableData.split('|');
  const tableList = tableDataList.map((table) => {
    const contentHeader = table.split('~')[0];
    const contentList = table.split('~')[1].split('*').map(
      (liText) => <ListItem key={liText}>{liText}</ListItem>
    );
    return (
      <Flex
        key={contentHeader}
        width="full"
        mx="2px"
        flexDirection="column"
        boxShadow="0px 2px 2px rgba(0, 0, 0, 0.25)"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          py="6px"
          color="white"
          bg="dietela.primary"
          w="full"
        >
          <Text
            as="h1"
            fontWeight="400"
            fontSize={{
              lg: '20px',
              md: '18px',
              sm: '16px',
              base: '14px'
            }}
            fontFamily="roboto"
          >
            {contentHeader}
          </Text>
        </Box>
        <Box
          bg="rgba(0, 140, 129, 0.25)"
          h="100%"
        >
          <UnorderedList
            fontWeight="400"
            textAlign="left"
            padding="8px"
            fontSize={{
              lg: '18px',
              md: '16px',
              sm: '14px',
              base: '12px'
            }}
            fontFamily="roboto"
          >
            {contentList}
          </UnorderedList>
        </Box>
      </Flex>
    );
  });

  return (
    <Box
      w="100%"
    >
      <Flex
        flexDir="row"
        justifyContent="center"
        alignItems="stretch"
        mb={mb}
      >
        {tableList}
      </Flex>
    </Box>
  );
}

export default CTATable;
