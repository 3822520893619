import React from 'react';
import { Center, Flex, Heading, Image, Text, Button } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ResultCardLayout from 'components/layout/ResultCardLayout';

function RecommendationCard() {
  const navigate = useNavigate();
  const result = useSelector((state) => state.finalResult.data);
  return (
    <ResultCardLayout>
      {result.conditional.map(
        (slide) =>
          slide.card === 'Slide 8' && (
            <Flex
              key={slide.card}
              flexDirection="column"
              justifyContent="start"
            >
              <Image
                src={slide.image}
                alt={slide.title}
                h="87px"
                w="100%"
                objectFit="cover"
              />
              <Flex
                flexDirection="column"
                justifyContent="center"
                flex="1"
                p={8}
                gap={5}
              >
                <Heading fontSize="xl" textAlign="left" w="100%">
                  {slide.title}
                </Heading>
                {slide.description.split('|').map(
                  (text, idx) => (
                    <Text
                      key={text}
                      fontFamily="roboto"
                      textAlign={{
                        base: idx === 2 || idx === 3 ? 'center' : 'left',
                        md: 'left'
                      }}
                      fontSize="16px"
                    >
                      {text.includes('~') && (text.includes('%') === 'false' || text.includes('%') === false)
                        ? (
                          text.split('~').map((bold, index) =>
                            <Text key={bold} as="span" fontWeight={index === 1 ? 'bold' : ''}>{bold}</Text>)
                        ) : ('')}
                      {text.includes('%')
                        ? (
                          text.split('%')[1].split('~').map((bold, index) => (
                            <Center>
                              <Flex
                                flexDirection={{
                                  base: 'column',
                                  md: 'row'
                                }}
                                gap={{
                                  base: '0',
                                  md: '3'
                                }}
                                alignItems="center"
                              >
                                <Text as="span" fontWeight={index === 1 ? 'bold' : ''}>{bold}</Text>
                                {index === 1 ? (
                                  <Image
                                    alt="Result Journal"
                                    src={slide.journalImage}
                                    h={{
                                      base: '77px',
                                      md: '86px'
                                    }}
                                  />
                                ) : ''}
                              </Flex>
                            </Center>
                          ))
                        ) : ('')}
                      {(text.includes('~') === 'false' || text.includes('~') === false) && (text.includes('*') === 'false' || text.includes('*') === false) && (text.includes('%') === 'false' || text.includes('%') === false)
                        ? (text) : ('')}
                    </Text>
                  )
                )}
                <Center
                  mt={{
                    base: 3,
                    md: -5,
                    lg: -5,
                    xl: 3
                  }}
                >
                  <Button
                    bg="dietela.secondary"
                    _active={{
                      bg: 'dietela.secondary-variant'
                    }}
                    _hover={{
                      bg: 'dietela.secondary-variant'
                    }}
                    onClick={() => navigate(slide.linkCta)}
                    color="black"
                    borderRadius="full"
                    letterSpacing={4}
                    textTransform="uppercase"
                    fontSize={{
                      base: '11px',
                      md: '9px'
                    }}
                  >
                    {slide.cta}
                  </Button>
                </Center>
              </Flex>
            </Flex>
          )
      )}
    </ResultCardLayout>
  );
}

export default RecommendationCard;
