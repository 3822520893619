import React, { useEffect, useState } from 'react';
import { Box, Image, Container, Text, Flex, Input, Button, InputGroup, InputLeftElement } from '@chakra-ui/react';
import axios from 'axios';
import { customBackground } from 'style/theme';
import { useNavigate } from 'react-router-dom';
import { EmailIcon } from '@chakra-ui/icons';

function Unsubscribe() {
  useEffect(() => {
    customBackground('#008C811A');
    return () => {
      document.body.style = '';
    };
  }, []);

  const [value, setValue] = useState('');
  const navigate = useNavigate();

  const handleChange = (event) => {
    if (event.target.value === '') {
      setValue('');
    } else {
      setValue(event.target.value);
    }
  };

  const handleUnsubscribe = async () => {
    try {
      const res = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API}email-reminder`,
        data: {
          email: value
        }
      });
      if (res) {
        navigate('/unsubscribe-success');
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container>
      <Box
        py={7}
        w="100%"
      >
        <Image
          src="https://ik.imagekit.io/dietela/unsubscribe.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1672633867358"
          alt="Logo Dietela"
          m="0 auto"
          h="120px"
        />
      </Box>
      <Box>
        <Text
          fontSize="22px"
          fontWeight="700"
          fontFamily="montserrat"
          my="10px"
          textAlign="center"
        >
          Sudah yakin tidak mau melanjutkan pemeriksaan dari dietela?
        </Text>
        <Text
          fontSize="18px"
          fontWeight="600"
          fontFamily="montserrat"
          my="10px"
          textAlign="center"
        >
          Isi email yang terdaftar di web aplikasi agar kamu tidak lagi dikirimkan notifikasi ya.
        </Text>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<EmailIcon color="teal" />}
          />
          <Input
            border="2px"
            borderColor="teal"
            placeholder="Masukkan Email"
            value={value}
            onChange={handleChange}
          />
        </InputGroup>
      </Box>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        my="24px"
      >
        <Button
          colorScheme="teal"
          size="md"
          onClick={handleUnsubscribe}
        >
          Hentikan Pengingat
        </Button>
      </Flex>
    </Container>
  );
}

export default Unsubscribe;
