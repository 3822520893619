import React from 'react';
import { Flex, Heading, Image, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import ResultCardLayout from 'components/layout/ResultCardLayout';

function BmiStatusCard() {
  const result = useSelector((state) => state.finalResult.data);
  console.log(result.conditional[3].status.value);
  let color;
  if (result.conditional[3].status.value < 18.50) {
    color = 'red';
  } else if (result.conditional[3].status.value <= 22.99) {
    color = 'dietela.primary';
  } else if (result.conditional[3].status.value <= 24.99) {
    color = 'progress.yellow';
  } else if (result.conditional[3].status.value <= 29.99) {
    color = 'red';
  } else {
    color = 'red';
  }
  return (
    <ResultCardLayout>
      {result.conditional.map((slide) =>
        slide.card === 'Slide 4' && (
          <Flex
            key={slide.card}
            flexDirection="column"
            justifyContent="start"
          >
            <Image
              src={slide.image}
              alt={slide.title}
              h="87px"
              w="100%"
              objectFit="cover"
            />
            <Flex
              flexDirection="column"
              justifyContent="center"
              flex="1"
              p={8}
              gap={5}
            >
              <Heading fontSize="xl" textAlign="center">{slide.title}</Heading>
              <Flex
                w="100%"
                flexDir={{
                  base: 'column',
                  md: 'row'
                }}
                alignItems="center"
                justifyContent="center"
                gap={{
                  base: '0',
                  md: '3'
                }}
              >
                <Heading fontSize="4xl" color={color}>
                  {slide.status.value}
                </Heading>
                <Text fontSize="md" fontWeight="semibold">
                  Status Gizi
                  {' '}
                  {slide.status.label}
                </Text>
              </Flex>
              {slide.description.split('|').map(
                (text) => (
                  <Text key={text}>
                    {text.includes('~')
                      ? (
                        text.split('~').map((bold, index) =>
                          <Text key={bold} as="span" fontWeight={index === 1 ? 'bold' : ''}>{bold}</Text>)
                      ) : (text)}
                  </Text>
                )
              )}
            </Flex>
          </Flex>
        ))}
    </ResultCardLayout>
  );
}

export default BmiStatusCard;
