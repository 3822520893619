import React from 'react';
import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Text,
  VStack
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import ResultCardLayout from 'components/layout/ResultCardLayout';

function ConditionChartCard() {
  const result = useSelector((state) => state.finalResult.data);
  function colorPercentage(category, percent) {
    if (category === 'Kondisi Personal') {
      if (percent <= 50) {
        return 'progress.red';
      }
      if (percent <= 89) {
        return 'progress.yellow';
      }
      return 'progress.green';
    }
    if (category === 'Pola Makan Sehat') {
      if (percent <= 57) {
        return 'progress.red';
      }
      if (percent <= 83) {
        return 'progress.yellow';
      }
      return 'progress.green';
    }
    if (category === 'Level Aktivitas Fisik') {
      if (percent <= 65) {
        return 'progress.red';
      }
      if (percent <= 85) {
        return 'progress.yellow';
      }
      return 'progress.green';
    }
    if (category === 'Kesiapan Hidup Sehat') {
      if (percent <= 35) {
        return 'progress.red';
      }
      if (percent <= 55) {
        return 'progress.yellow';
      }
      return 'progress.green';
    }
    if (category === 'Status Perilaku Makan') {
      if (percent <= 15) {
        return 'progress.green';
      }
      if (percent <= 30) {
        return 'progress.yellow';
      }
      return 'progress.red';
    }
    if (category === 'Pemahaman Gizi dan Hidup Sehat') {
      if (percent <= 55) {
        return 'progress.red';
      }
      if (percent <= 80) {
        return 'progress.yellow';
      }
      return 'progress.green';
    }
  }

  return (
    <ResultCardLayout>
      {result.conditional.map(
        (slide) =>
          slide.card === 'Slide 2' && (
            <Flex
              key={slide.title}
              flexDirection="column"
              justifyContent="start"
            >
              <Image
                src={slide.image}
                alt={slide.title}
                h="87px"
                w="100%"
                objectFit="cover"
              />
              <Flex
                flexDirection="column"
                justifyContent="center"
                p={8}
                gap={3}
                flex="1"
              >
                <Heading fontSize="xl" textAlign="center">{slide.title}</Heading>
                <Grid
                  w="100%"
                  templateRows={{
                    base: 'repeat(3, 1fr)',
                    md: 'repeat(2, 1fr)',
                    lg: 'repeat(2, 1fr)'
                  }}
                  templateColumns={{
                    base: 'repeat(2, 1fr)',
                    md: 'repeat(3, 1fr)',
                    lg: 'repeat(3, 1fr)'
                  }}
                  gap={3}
                  mt={6}
                >
                  {slide.percentage.map((status) => (
                    <GridItem key={status.label}>
                      <VStack>
                        <CircularProgress
                          size="100px"
                          value={status.value}
                          color={colorPercentage(status.label, Math.round(status.value))}
                          capIsRound
                        >
                          <CircularProgressLabel
                            fontSize="xl"
                            fontWeight="bold"
                          >
                            {Math.round(status.value)}
                            %
                          </CircularProgressLabel>
                        </CircularProgress>
                        <Text textAlign="center" fontSize="sm">
                          {status.label}
                        </Text>
                      </VStack>
                    </GridItem>
                  ))}
                </Grid>
                <Flex
                  fontFamily="roboto"
                  fontSize={{
                    base: '6px',
                    md: '12px',
                    lg: '12px'
                  }}
                  mt={{
                    base: 2,
                    md: 6,
                    lg: 6
                  }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box bg="progress.green" h="10px" w="10px" />
                  <Text
                    as="span"
                    ml="5px"
                  >
                    Mendekati Optimal
                  </Text>
                  <Box bg="progress.yellow" ml="10px" h="10px" w="10px" />
                  <Text
                    as="span"
                    ml="5px"
                  >
                    Kurang Optimal
                  </Text>
                  <Box bg="progress.red" ml="10px" h="10px" w="10px" />
                  <Text
                    as="span"
                    ml="5px"
                  >
                    Sangat Tidak Optimal
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          )
      )}
    </ResultCardLayout>
  );
}

export default ConditionChartCard;
