import React, { useEffect, useState } from 'react';
import { Box, Button, Center, Container, Flex, Skeleton, Text, VStack, Spacer } from '@chakra-ui/react';
import axios from 'axios';

import BaseLayout from 'components/layout/BaseLayout';
import Question from 'components/Question';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { setData, setChoice, setDisableNext, setLastNavigate, Prev, setLastQuestion, setArrBack, removeArrBack, removeScoreBack, setScoreBack, setArr, setScore, setCondition, setChoiceGroup } from 'reducer/questionReducer';
// import CTA from 'views/CTA';
import ButtonSecondary from 'components/Button/ButtonSecondary';
import ProgressBar from 'components/common/ProgressBar';
import { useNavigate } from 'react-router-dom';
import CTAPage from 'components/CTA/CTAPage';
import { setUserId } from 'reducer/userReducer';

function QuestionPage() {
  const navigate = useNavigate();

  const [isLoaded, setIsLoaded] = useState(false);

  const data = useSelector((state) =>
    state.question.data);
  const userId = useSelector((state) =>
    state.user.userId);
  const choice = useSelector((state) =>
    state.question.choice);
  const choiceGroup = useSelector((state) =>
    state.question.choiceGroup);
  const disableNext = useSelector((state) =>
    state.question.disableNext);
  const lastNavigate = useSelector((state) =>
    state.question.lastNavigate);
  const dispatch = useDispatch();
  const disablePrev = useSelector((state) =>
    state.question.disablePrev);
  const lastQuestion = useSelector((state) =>
    state.question.lastQuestion);
  const arrBack = useSelector((state) =>
    state.question.arrBack);
  const scoreBack = useSelector((state) =>
    state.question.scoreBack);
  const condition = useSelector((state) =>
    state.question.condition);

  useEffect(() => {
    if (data?.navigate?.last_child_position) {
      // console.log('kondisi 1');
      dispatch(setLastNavigate({
        id_category: lastNavigate.id_category,
        next_page: lastNavigate.next_page + 1,
        part: lastNavigate.part,
        cat: lastNavigate.cat
      }));
    } else if (data?.navigate?.last_part_position) {
      // console.log('kondisi 2');
      const num = lastNavigate.id_category + 1;
      const newPart = num.toString();
      dispatch(setLastNavigate({
        id_category: num,
        next_page: 1,
        part: newPart,
        cat: lastNavigate.cat
      }));
      dispatch(setCondition());
    } else if (!data?.navigate?.last_part_position && !data?.navigate?.next_page) {
      // console.log('kondisi 3');
      dispatch(setLastNavigate({
        id_category: lastNavigate?.id_category,
        next_page: lastNavigate?.next_page,
        part: lastNavigate?.part,
        cat: lastNavigate.cat
      }));
    } else {
      // console.log('kondisi 4');
      dispatch(setLastNavigate({
        id_category: data?.navigate?.id_category,
        next_page: data?.navigate?.next_page,
        part: data?.navigate?.part,
        cat: data?.navigate?.name_category
      }));
    }
  }, [data]);

  async function getFirstData() {
    try {
      dispatch(setDisableNext(true));
      dispatch(Prev(false));
      setIsLoaded(false);
      const res = await axios.get(`${process.env.REACT_APP_API}questions?page=1&category=1`);
      if (res) {
        dispatch(setData(res.data));
        setIsLoaded(true);
        dispatch(setArrBack(res.data));
      }
    } catch (err) {
      console.log(err);
    }
    return null;
  }

  async function postAnswer() {
    try {
      setIsLoaded(false);
      dispatch(setScoreBack(choice?.choiceScore));
      const res = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API}questions`,
        data: {
          user_id: userId,
          part: lastNavigate?.part,
          next_page: lastNavigate?.next_page,
          id_category: lastNavigate?.id_category,
          id_question: data?.data?.id,
          question_type: choice?.question_type,
          users_choice: choice?.userChoice,
          score: choice?.choiceScore,
          next_cta: data?.data?.next_cta,
          options: choice?.options,
          conditions: condition
        },
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      });
      if (res) {
        // console.log(res);
        dispatch(setChoice([]));
        dispatch(setData(res.data));
        setIsLoaded(true);
        dispatch(setDisableNext(true));
        if (lastQuestion?.response_type === 'cta') {
          dispatch(Prev(true));
        } else {
          dispatch(Prev(false));
        }
        dispatch(setLastQuestion({
          position: parseInt(lastQuestion?.position, 10) + 1,
          response_type: res.data.response_type
        }));
        dispatch(setArrBack(res.data));
      }
    } catch (err) {
      console.log(err);
    }
    return null;
  }

  async function postGroup() {
    try {
      setIsLoaded(false);
      dispatch(setScoreBack(choice?.choiceScore));
      const res = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API}questions/group`,
        data: {
          user_id: userId,
          part: lastNavigate?.part,
          next_page: lastNavigate?.next_page,
          id_category: lastNavigate?.id_category,
          score: choice?.choiceScore,
          data: choiceGroup
        },
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      });
      if (res) {
        dispatch(setChoice([]));
        dispatch(setChoiceGroup());
        dispatch(setData(res.data));
        setIsLoaded(true);
        dispatch(setDisableNext(true));
        if (lastQuestion?.response_type === 'cta') {
          dispatch(Prev(true));
        } else {
          dispatch(Prev(false));
        }
        dispatch(setLastQuestion({
          position: parseInt(lastQuestion?.position, 10) + 1,
          response_type: res.data.response_type
        }));
        dispatch(setArrBack(res.data));
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getPrev() {
    if (lastQuestion?.position === '0' || lastQuestion?.position === 0) {
      navigate('/name');
      dispatch(setArrBack());
    } else {
      try {
        const pos = parseInt(lastQuestion?.position, 10) - 1;
        dispatch(setData(arrBack[pos]));
        setIsLoaded(true);
        dispatch(setDisableNext(true));
        if (arrBack[pos - 1]) {
          if (arrBack[pos - 1].response_type === 'cta') {
            dispatch(Prev(true));
          } else {
            dispatch(Prev(false));
          }
        }
        dispatch(setLastQuestion({
          position: parseInt(lastQuestion?.position, 10) - 1
        }));
        dispatch(removeArrBack());
        await axios({
          method: 'post',
          url: `${process.env.REACT_APP_API}questions/scoreback`,
          data: {
            user_id: userId,
            part: lastNavigate?.part,
            score: scoreBack[pos]
          },
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        });
        dispatch(removeScoreBack());
      } catch (err) {
        console.log(err);
      }
      return null;
    }
  }

  async function getBranch(pid) {
    try {
      setIsLoaded(false);
      dispatch(setScoreBack(choice?.choiceScore));
      const res = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API}questions/parent?pid=${pid}&page=1`,
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      });
      if (res) {
        // console.log(res);
        dispatch(setChoice([]));
        dispatch(setData(res.data));
        setIsLoaded(true);
        dispatch(setDisableNext(true));
        if (lastQuestion?.response_type === 'cta') {
          dispatch(Prev(true));
        } else {
          dispatch(Prev(false));
        }
        dispatch(setLastQuestion({
          position: parseInt(lastQuestion?.position, 10) + 1,
          response_type: res.data.response_type
        }));
        dispatch(setArrBack(res.data));
      }
    } catch (err) {
      console.log(err);
    }
    return null;
  }

  async function setDataLocal() {
    const arr = JSON.parse(localStorage.getItem('data'));
    const pos = arr.length - 1;
    dispatch(setData(arr[pos]));
    setIsLoaded(true);
    dispatch(setLastQuestion({
      position: parseInt(localStorage.getItem('position'), 10),
      response_type: arr[pos].data.response_type
    }));
    return null;
  }

  useEffect(() => {
    dispatch(setDisableNext(true));
    dispatch(Prev(false));
    if (localStorage.getItem('user_id') !== '' && (userId === 0 || userId === '0')) {
      dispatch(setUserId(localStorage.getItem('user_id')));
    }
    // localStorage.removeItem('data');
    if (localStorage.getItem('data')) {
      dispatch(setArr(JSON.parse(localStorage.getItem('data'))));
      dispatch(setScore(JSON.parse(localStorage.getItem('score'))));
      dispatch(setLastNavigate(JSON.parse(localStorage.getItem('last_navigate'))));
      dispatch(setCondition(parseInt(localStorage.getItem('condition'), 10)));
      const arr = JSON.parse(localStorage.getItem('data'));
      const pos = arr.length - 1;
      if (arr[pos - 1]) {
        if (arr[pos - 1].response_type === 'cta') {
          dispatch(Prev(true));
        } else {
          dispatch(Prev(false));
        }
      }
      setDataLocal();
    } else {
      getFirstData();
    }
  }, []);

  useEffect(() => {
    if (arrBack.length !== 0) {
      localStorage.setItem('data', JSON.stringify(arrBack));
    }
  }, [arrBack]);

  useEffect(() => {
    if (scoreBack.length !== 0) {
      localStorage.setItem('score', JSON.stringify(scoreBack));
    }
  }, [scoreBack]);

  useEffect(() => {
    if (parseInt(lastQuestion.position, 10) !== 0) {
      localStorage.setItem('position', parseInt(lastQuestion.position, 10));
    }
  }, [lastQuestion]);

  useEffect(() => {
    localStorage.setItem('condition', condition);
  }, [condition]);

  useEffect(() => {
    if (JSON.stringify(lastNavigate) !== '{}') {
      localStorage.setItem('last_navigate', JSON.stringify(lastNavigate));
    }
  }, [lastNavigate]);

  const handleNext = () => {
    if (data?.data?.suggest_next) {
      getBranch(data?.data?.suggest_next);
    } else if (data?.data?.is_finished) {
      navigate('/result');
    } else if (data?.data?.question_type === 'radio_group') {
      postGroup();
    } else postAnswer();
  };

  const handlePrev = () => {
    getPrev();
  };

  // progress bar
  const progress = data?.data?.progress;
  let question = [];
  if (progress != null) {
    question = progress.split('/');
  }

  let progressBar = '';
  let awal = '';
  let total = '';

  if (condition === 1) {
    progressBar = '2/6';
    awal = 2;
    total = 6;
  } else if (condition === 2) {
    progressBar = '2/9';
    awal = 2;
    total = 9;
  } else if (condition === 3) {
    progressBar = '2/10';
    awal = 2;
    total = 10;
  }

  // dispatch(setScoreBack(0));
  // console.log(data?.data?.question_type);
  // console.log(arrBack);
  // console.log(lastQuestion);
  // console.log(JSON.stringify(lastNavigate));
  // console.log(lastQuestion?.position);
  // console.log(localStorage.getItem('data'));
  // console.log(JSON.parse(localStorage.getItem('data')));
  // localStorage.removeItem('data');
  // console.log(choiceGroup);
  // console.log(data?.data);

  return (
    <BaseLayout>
      <Box
        alignItems="center"
        minH="calc(100vh - 103px)"
        boxSizing="border-box"
      >
        <Container maxW={(data?.response_type === 'range' || data?.response_type === 'cta') || data?.response_type === 'multiple' ? '80ch' : '70ch'}>
          <VStack>
            {data?.response_type !== 'cta' && data?.data?.question_type !== 'radio_button' && data?.data?.question_type !== 'form'
              ? (
                <Skeleton isLoaded={isLoaded}>
                  <Text fontFamily="roboto" fontWeight="700" fontSize="22px" color="dietela.primary">
                    {data?.navigate?.name_category
                      ? data.navigate?.name_category : lastNavigate?.cat}
                  </Text>
                  <Text align="center" fontFamily="roboto" fontWeight="500" fontSize="16px" color="dietela.primary">
                    Pertanyaan&nbsp;
                    {data?.data?.question_type === 'number' ? progressBar : data?.data?.progress}
                  </Text>
                </Skeleton>
              ) : (
                ''
              )}
            {data?.response_type !== 'cta' && data?.data?.question_type !== 'radio_button' && data?.data?.question_type !== 'form'
              ? (
                <Skeleton isLoaded={isLoaded} w="100%">
                  {data?.data?.question_type === 'number'
                    ? (
                      <ProgressBar
                        totalPart={total}
                        part={awal}
                      />
                    ) : (
                      <ProgressBar
                        totalPart={question[1]}
                        part={question[0]}
                      />
                    )}
                </Skeleton>
              ) : (
                ''
              )}
          </VStack>
        </Container>
        {data.response_type === 'cta'
          ? (
            <VStack
              pb="24px"
            >
              <CTAPage
                CTAComponentsData={data?.data?.CTADetails}
                CTA={data?.data?.score_condition}
              />
              <Skeleton isLoaded={isLoaded}>
                <Center>
                  <ButtonSecondary cta="Selanjutnya" handleClick={handleNext} />
                </Center>
              </Skeleton>
            </VStack>
          ) : (
            <Container py={10} maxW={data?.response_type === 'range' || data?.response_type === 'multiple' ? '80ch' : '70ch'}>
              <Skeleton isLoaded={isLoaded}>
                <Question data={data} />
              </Skeleton>
              <Skeleton isLoaded={isLoaded}>
                <Flex justifyContent="space-between">
                  {disablePrev === true
                    ? (
                      <Spacer />
                    ) : (
                      <Button
                        px={6}
                        borderRadius="full"
                        leftIcon={<ArrowBackIcon />}
                        alignSelf="end"
                        bg="dietela.secondary"
                        _active={{
                          bg: 'dietela.secondary-variant'
                        }}
                        _hover={{
                          bg: 'dietela.secondary-variant'
                        }}
                        onClick={handlePrev}
                        disabled={disablePrev}
                        opacity={disablePrev === true ? '0%' : '100%'}
                        size={{
                          lg: 'md',
                          md: 'md',
                          sm: 'sm',
                          base: 'sm'
                        }}
                      >
                        Sebelumnya
                      </Button>
                    )}
                  {data?.data?.question_type === 'radio_button' || data?.data?.question_type === 'number'
                    ? (
                      ''
                    ) : (
                      <Button
                        className="next-button"
                        px={6}
                        borderRadius="full"
                        alignSelf="end"
                        rightIcon={<ArrowForwardIcon />}
                        bg="dietela.secondary"
                        _active={{
                          bg: 'dietela.secondary-variant'
                        }}
                        _hover={{
                          bg: 'dietela.secondary-variant'
                        }}
                        onClick={handleNext}
                        disabled={disableNext}
                        size={{
                          lg: 'md',
                          md: 'md',
                          sm: 'sm',
                          base: 'sm'
                        }}
                      >
                        Selanjutnya
                      </Button>
                    )}
                </Flex>
              </Skeleton>
              {data?.data?.question_type === 'radio_button' || data?.data?.question_type === 'number'
                ? (
                  <Skeleton isLoaded={isLoaded}>
                    <Center>
                      <Button
                        className="next-button"
                        px={6}
                        borderRadius="full"
                        alignSelf="center"
                        bg="dietela.secondary"
                        _active={{
                          bg: 'dietela.secondary-variant'
                        }}
                        _hover={{
                          bg: 'dietela.secondary-variant'
                        }}
                        onClick={handleNext}
                        disabled={disableNext}
                        size={{
                          lg: 'md',
                          md: 'md',
                          sm: 'sm',
                          base: 'sm'
                        }}
                      >
                        Selanjutnya
                      </Button>
                    </Center>
                  </Skeleton>
                ) : (
                  ''
                )}
            </Container>
          )}
      </Box>
    </BaseLayout>
  );
}

export default QuestionPage;
