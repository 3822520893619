import React, { useEffect, useState } from 'react';
import { Box, RadioGroup, Radio, Stack } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { setChoice, setDisableNext, setAnswers, setCondition } from 'reducer/questionReducer';
import { storeAnswer } from 'utils/localStorage';

function RadioInput({ data }) {
  const [value, setValue] = useState('');
  const dispatch = useDispatch();
  const currentQuestion = useSelector((state) =>
    state.question.data);
  // const choice = useSelector((state) =>
  //   state.question.choice);
  const question = data.data.question_type;

  const handleChange = (item) => {
    // console.log(item);
    const newObj = {
      question_type: question,
      userChoice: item.id,
      choiceScore: item.score
    };
    dispatch(setChoice(newObj));
    // save answers to local storage
    const answerList = storeAnswer({
      question_id: currentQuestion?.data?.id,
      question_type: currentQuestion?.data?.question_type,
      answer: {
        option_id: item.id,
        option: item.option
      },
      choiceProps: newObj
    });
    dispatch(setAnswers(answerList));
    if (item.id === '142' || item.id === 142) {
      dispatch(setCondition(2));
    } else if (item.id === '143' || item.id === 143) {
      dispatch(setCondition(3));
    } else if (item.id === '141' || item.id === 141) {
      dispatch(setCondition(1));
    }
  };

  useEffect(() => {
    if (value === '') {
      dispatch(setDisableNext(true));
      // Set Selected Radio Option Answered from Local Storage
      if (localStorage.getItem('user_answers')) {
        if (JSON.parse(localStorage.getItem('user_answers')).some((answer) => answer.question_id === currentQuestion?.data?.id)) {
          const { answer, choiceProps } = JSON.parse(localStorage.getItem('user_answers')).filter((answer) => answer.question_id === currentQuestion?.data?.id)[0];
          if (answer.option) setValue(answer.option);
          if (choiceProps) dispatch(setChoice(choiceProps));
          if (answer.option_id === '142' || answer.option_id === 142) {
            dispatch(setCondition(2));
          } else if (answer.option_id === '143' || answer.option_id === 143) {
            dispatch(setCondition(3));
          } else if (answer.option_id === '141' || answer.option_id === 141) {
            dispatch(setCondition(1));
          }
        }
      }
    } else dispatch(setDisableNext(false));
  }, [value]);

  useEffect(() => {
    if (localStorage.getItem('user_answers')) {
      if (JSON.parse(localStorage.getItem('user_answers')).some((answer) => answer.question_id === currentQuestion?.data?.id)) {
        const { answer, choiceProps } = JSON.parse(localStorage.getItem('user_answers')).filter((answer) => answer.question_id === currentQuestion?.data?.id)[0];
        if (answer.option) setValue(answer.option);
        if (choiceProps) dispatch(setChoice(choiceProps));
        if (answer.option_id === '142' || answer.option_id === 142) {
          dispatch(setCondition(2));
        } else if (answer.option_id === '143' || answer.option_id === 143) {
          dispatch(setCondition(3));
        } else if (answer.option_id === '141' || answer.option_id === 141) {
          dispatch(setCondition(1));
        }
        dispatch(setDisableNext(false));
      }
    }
  }, [currentQuestion]);

  const parseOption = (text) => {
    const option = text.split('.');
    if (option.length === 1) {
      return option[0];
    }
    return option[1];
  };

  return (
    <RadioGroup onChange={setValue} value={value} colorScheme="white">
      <Stack direction="column">
        {data.data.Options.map((item) =>
          (
            <Box
              key={item.id}
              borderRadius={3}
              cursor="pointer"
              color={
                value === item.option
                  ? 'white'
                  : 'black'
                }
              bg={
                value === item.option
                  ? 'dietela.primary'
                  : '#F2F2F2F2'
                }
              _hover={{
                color: 'white',
                bg: 'dietela.primary'
              }}
            >
              <Radio
                w="100%"
                px={4}
                py={3}
                value={item.option}
                onChange={() => handleChange(item)}
              >
                {parseOption(item.option)}

              </Radio>
            </Box>
          ))}
      </Stack>
    </RadioGroup>
  );
}

export default RadioInput;
