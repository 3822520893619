import React from 'react';
import { Box, Image } from '@chakra-ui/react';

function Navbar() {
  return (
    <Box
      py={7}
      w="100%"
    >
      <Image
        src="https://ik.imagekit.io/dietela/Logo_dietela.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1672633056849"
        h={{
          lg: '45px',
          md: '36px',
          base: '32px'
        }}
        alt="Logo Dietela"
      />
    </Box>
  );
}

export default Navbar;
