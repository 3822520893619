import React from 'react';
import { Box, Heading } from '@chakra-ui/react';

function CTAH2({ text, pb }) {
  return (
    <Box
      pb={pb}
      w="100%"
    >
      <Heading
        fontSize={{
          lg: '18px',
          md: '18px',
          base: '14px'
        }}
        fontFamily="montserrat"
        fontWeight="semibold"
      >
        { text }
      </Heading>
    </Box>
  );
}

export default CTAH2;
