import React from 'react';
import { Box, VStack, Heading, Text } from '@chakra-ui/react';
import TextInput from 'components/Answer/TextInput';
import RadioInput from 'components/Answer/RadioInput';
import CheckboxInput from 'components/Answer/CheckboxInput';
import RangeInput from 'components/Answer/RangeInput';
import NumInput from 'components/Answer/NumInput';
import MultipleChoice from 'components/Answer/MultipleChoice';
import AgeInput from 'components/Answer/AgeInput';
import RadioButtonInput from 'components/Answer/RadioButtonInput';
import RadioGroupInput from 'components/Answer/RadioGroupInput';
import { useSelector } from 'react-redux';
import posthog from 'posthog-js';

function QuestionType(data) {
  switch (data?.data?.question_type) {
    case 'form':
      return <TextInput data={data} />;
    case 'radio':
      return <RadioInput data={data} />;
    case 'radio_gender':
      return <RadioInput data={data} />;
    case 'radio_job':
      return <RadioInput data={data} />;
    case 'checkbox':
      return <CheckboxInput data={data} />;
    case 'range':
      return <RangeInput data={data} />;
    case 'multiple':
      return <MultipleChoice data={data} />;
    case 'number':
      return <NumInput data={data} />;
    case 'age':
      return <AgeInput data={data} />;
    case 'radio_scoring':
      return <RadioInput data={data} />;
    case 'radio_button':
      return <RadioButtonInput data={data} />;
    case 'radio_group':
      return <RadioGroupInput data={data} />;
    default:
      return null;
  }
}

function Question({ data }) {
  const condition = useSelector((state) =>
    state.question.condition);
  let questionRows;
  if (data?.data?.question) {
    questionRows = data?.data?.question.split('|').map(
      (question) => {
        let questionColor = 'black';
        if (question.indexOf('~') === 0) {
          question = question.replace('~', '');
          questionColor = '#707070';
        }
        if (data?.data?.id === 11 && condition === 3) {
          question = 'Silahkan isi berat badanmu sebelum hamil dan tinggi badanmu';
        }
        return (
          <Box
            mb="16px"
            key={question}
          >
            <Text
              color={questionColor}
            >
              {question}
            </Text>
          </Box>
        );
      }
    );
  }
  posthog.capture(`NAMA Question: ${data?.data?.question}`, {
    page: 'Input Name'
  });

  return (
    <Box mb={12}>
      <VStack spacing={10} alignItems="start">
        {data?.data?.question_type === 'radio_group'
          ? (
            ''
          ) : (
            <Heading fontSize={{
              base: '18px', md: '20px'
            }}
            >
              {questionRows}
            </Heading>
          )}
        <Box width="100%">
          {QuestionType(data)}
        </Box>
      </VStack>
    </Box>
  );
}

export default Question;
