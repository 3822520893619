/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const userSlicer = createSlice({
  name: 'user',
  initialState: {
    userId: 0
  },

  reducers: {
    setUserId: (state, action) => {
      if (!action.payload) {
        state.userId = 0;
      }
      state.userId = action.payload;
    }
  }
});

export const { setUserId } = userSlicer.actions;
export default userSlicer.reducer;
