import React, { useEffect, useState } from 'react';
import { Box, Input, InputGroup, InputRightAddon, Text, VStack } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { Prev, setChoice, setDisableNext, setAnswers } from 'reducer/questionReducer';
import { storeAnswer } from 'utils/localStorage';

function AgeInput() {
  const dispatch = useDispatch();
  const currentQuestion = useSelector((state) =>
    state.question.data);

  const [input, setInput] = useState({
    age: ''
  });

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setInput({
      ...input,
      [name]: value
    });

    if (input.age) {
      const newObj = {
        question_type: 'age',
        userChoice: 0,
        choiceScore: 0,
        options: {
          number_value: {
            age: parseInt(value, 10)
          }
        }
      };
      dispatch(setChoice(newObj));
      // save answers to local storage
      const answerList = storeAnswer({
        question_id: currentQuestion?.data?.id,
        question_type: currentQuestion?.data?.question_type,
        answer: {
          ...input,
          [name]: value
        },
        choiceProps: newObj
      });
      dispatch(setAnswers(answerList));
    }
  };

  useEffect(() => {
    if (parseInt(input.age, 10) < 19 || input.age === '' || parseInt(input.age, 10) > 150) {
      // if (parseInt(input.weight, 10) >= 20 && parseInt(input.weight, 10) <= 400) {
      // }
      dispatch(setDisableNext(true));
      dispatch(Prev(false));
      // Set Selected Radio Option Answered from Local Storage
      if (localStorage.getItem('user_answers')) {
        if (JSON.parse(localStorage.getItem('user_answers')).some((answer) => answer.question_id === currentQuestion?.data?.id)) {
          const { answer, choiceProps } = JSON.parse(localStorage.getItem('user_answers')).filter((answer) => answer.question_id === currentQuestion?.data?.id)[0];
          if (answer.age) setInput(answer);
          if (choiceProps) dispatch(setChoice(choiceProps));
        }
      }
    } else {
      dispatch(setDisableNext(false));
      dispatch(Prev(false));
    }
  }, [input]);

  return (
    <VStack as="form" spacing={2}>
      <Box w="100%">
        <InputGroup>
          <Input
            w="100%"
            type="number"
            name="age"
            value={input.age}
            onChange={handleChange}
            placeholder="Type your answer here..."
            variant="flushed"
            textColor="black"
            borderColor="dietela.primary"
            max="150"
          />
          <InputRightAddon children="Tahun" bg="transparent" border="0px" />
        </InputGroup>
        <Text mt={1} fontSize="sm" color="gray.500">
          Umur harus diatas 19 tahun
        </Text>
      </Box>
    </VStack>
  );
}

export default AgeInput;
