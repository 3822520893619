import React from 'react';
import { useSelector } from 'react-redux';
import { Flex, Heading, Image, Text } from '@chakra-ui/react';
import ResultCardLayout from 'components/layout/ResultCardLayout';

function ConditionTextCard() {
  const result = useSelector((state) => state.finalResult.data);
  return (
    <ResultCardLayout>
      {result.conditional.map(
        (slide) =>
          slide.card === 'Slide 1' && (
            <Flex
              key={slide.title}
              flexDirection="column"
            >
              <Image
                src={slide.image}
                alt={slide.title}
                h="87px"
                w="100%"
                objectFit="cover"
              />
              <Flex
                p={8}
                gap={5}
                flex="1"
                flexDirection="column"
                justifyContent="center"
              >
                <Heading fontSize="xl" textAlign="center">{slide.title}</Heading>
                {slide.description.split('|').map(
                  (text) => <Text key={text}>{text}</Text>
                )}
              </Flex>
            </Flex>
          )
      )}
    </ResultCardLayout>
  );
}

export default ConditionTextCard;
