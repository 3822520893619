import React, { useEffect } from 'react';
import {
  Center,
  Flex,
  Image
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Navbar from 'components/common/Navbar';
// import BaseLayout from 'components/layout/BaseLayout';
import { customBackground } from 'style/theme';
import ButtonSecondary from 'components/Button/ButtonSecondary';
import CTAH1 from 'components/CTA/CTAH1';
import CTAImage from 'components/CTA/CTAImage';
import CTAText from 'components/CTA/CTAText';
import CTATextBox from 'components/CTA/CTATextBox';
import posthog from 'posthog-js';

function InstructionPage() {
  posthog.capture('NAMA Opening Screen', {
    page: 'Opening Screen'
  });
  const navigate = useNavigate();

  useEffect(() => {
    customBackground('#008C811A');
    return () => {
      document.body.style = '';
    };
  }, []);

  return (
    <Flex justifyContent="between">
      <Flex
        flexDirection="column"
        alignItems="start"
        px={['1rem', '5rem']}
        h="100vh"
      >
        <Navbar />
        <Center
          display="flex"
          flexDirection="column"
          alignItems="center"
          h="full"
        >
          <CTAH1 text="Sudahkah kamu mengenal tubuhmu?" mb="0px" />
          <CTAH1 text="Ingin tahu apa yang tubuhmu butuhkan untuk menjadi dirimu versi lebih baik dan sehat?" pb="24px" />
          <CTAH1 text="Yuk Dietela bantu…" pb="10px" />
          <CTAImage url="https://ik.imagekit.io/dietela/svg_webp/opening_screening.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1672636869422" pb="10px" />
          <CTAText text="Dengan mengisi quiz selama 5 menit saja, kamu akan mendapatkan penilaian gizi sesuai kondisimu dan informasi penting untuk menjadi lebih sehat." pb="10px" />
          <CTATextBox textHeading="Perhatian!" text="Quiz ini hanya cocok diisi jika kamu berusia 19 tahun keatas ya." textAlign="left" mb="10px" />
          <Flex
            width="full"
            justifyContent={{
              base: 'center',
              md: 'center'
            }}
          >
            <ButtonSecondary
              cta="Mulai Quiz"
              handleClick={() => navigate('/name')}
            />
          </Flex>
        </Center>
      </Flex>
      <Image
        src="https://ik.imagekit.io/dietela/Part1_RightImage_compress.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1672633888788"
        h="100vh"
        minW="500px"
        alt="rightImage"
        display={{
          base: 'none',
          lg: 'block'
        }}
      />
    </Flex>
  );
}

export default InstructionPage;
