import React, { useEffect, useState } from 'react';
import { Box, Input, InputGroup, InputRightAddon, Text, VStack, Center } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { setChoice, setDisableNext, setAnswers } from 'reducer/questionReducer';
import { storeAnswer } from 'utils/localStorage';

function NumInput() {
  const dispatch = useDispatch();
  const currentQuestion = useSelector((state) =>
    state.question.data);

  const [input, setInput] = useState({
    weight: '',
    height: ''
  });

  function calculateIMT(weight, height) {
    const scoring = parseFloat(weight / ((height / 100) * (height / 100)), 10).toFixed(2);
    let imt;
    if (scoring >= 30) {
      imt = 1;
    } else if (scoring >= 25 && scoring < 29.99) {
      imt = 1;
    } else if (scoring >= 23 && scoring < 25) {
      imt = 2;
    } else if (scoring > 18.5 && scoring < 22.99) {
      imt = 3;
    } else if (scoring < 18.50) {
      imt = 2;
    }
    return imt;
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInput({
      ...input,
      [name]: value
    });
    const answerList = storeAnswer({
      question_id: currentQuestion?.data?.id,
      question_type: currentQuestion?.data?.question_type,
      answer: {
        ...input,
        [name]: value
      }
    });
    dispatch(setAnswers(answerList));
  };

  useEffect(() => {
    if (localStorage.getItem('user_answers')) {
      if (JSON.parse(localStorage.getItem('user_answers')).some((answer) => answer.question_id === currentQuestion?.data?.id)) {
        const { answer, choiceProps } = JSON.parse(localStorage.getItem('user_answers')).filter((answer) => answer.question_id === currentQuestion?.data?.id)[0];
        if (answer) setInput(answer);
        if (choiceProps) dispatch(setChoice(choiceProps));
      }
    }
  }, []);

  useEffect(() => {
    if ((parseInt(input.weight, 10) < 20 || parseInt(input.weight, 10) > 400)
    || (parseInt(input.height, 10) < 80 || parseInt(input.height, 10) > 250) || input.height === '' || input.weight === '') {
      dispatch(setDisableNext(true));
    } else {
      if (input.height && input.weight) {
        const score = calculateIMT(input.weight, input.height);
        const newObj = {
          question_type: 'number',
          userChoice: 0,
          choiceScore: score,
          options: {
            number_value: {
              berat_badan: parseInt(input.weight, 10),
              tinggi_badan: parseInt(input.height, 10)
            }
          }
        };
        dispatch(setChoice(newObj));
        const answerList = storeAnswer({
          question_id: currentQuestion?.data?.id,
          question_type: currentQuestion?.data?.question_type,
          answer: {
            weight: parseInt(input.weight, 10),
            height: parseInt(input.height, 10)
          },
          choiceProps: newObj
        });
        dispatch(setAnswers(answerList));
      }
      dispatch(setDisableNext(false));
    }
  }, [input]);

  return (
    <VStack as="form" spacing={6}>
      <Box>
        <InputGroup
          size="md"
          borderRadius="md"
          width="280px"
          borderColor="black"
          boxShadow="sm"
        >
          <Input
            type="number"
            name="weight"
            value={input.weight}
            onChange={handleChange}
            textAlign="center"
            _hover={{
              borderColor: 'black'
            }}
          />
          <InputRightAddon children="kg" bg="transparent" />
        </InputGroup>
        { (parseInt(input.weight, 10) < 20 || parseInt(input.weight, 10) > 400)
          ? (
            <Center>
              <Text mt={1} fontSize="sm" color="red">
                berat badan hanya bisa 20 - 400
              </Text>
            </Center>
          ) : (
            ''
          )}
      </Box>
      <Box>
        <InputGroup
          size="md"
          borderRadius="md"
          width="280px"
          borderColor="black"
          boxShadow="sm"
        >
          <Input
            type="number"
            name="height"
            value={input.height}
            onChange={handleChange}
            textAlign="center"
            _hover={{
              borderColor: 'black'
            }}
          />
          <InputRightAddon children="cm" bg="transparent" />
        </InputGroup>
        { (parseInt(input.height, 10) < 20 || parseInt(input.height, 10) > 400)
          ? (
            <Center>
              <Text mt={1} fontSize="sm" color="red">
                tinggi badan hanya bisa 80 - 250
              </Text>
            </Center>
          ) : (
            ''
          )}
      </Box>
    </VStack>
  );
}

export default NumInput;
