import React, { useEffect } from 'react';
import { Box, Image, Divider, Container, Text, Flex, Button, Grid, GridItem, VStack, CircularProgress, CircularProgressLabel } from '@chakra-ui/react';
// import BaseLayout from 'components/layout/BaseLayout';
import { customBackground } from 'style/theme';

import logoDietela from 'assets/image/Logo_dietela.png';

function Email() {
  useEffect(() => {
    customBackground('#008C811A');
    return () => {
      document.body.style = '';
    };
  }, []);

  function colorPercentage(category, percent) {
    if (category === 'Kondisi Personal') {
      if (percent <= 50) {
        return 'progress.red';
      }
      if (percent <= 89) {
        return 'progress.yellow';
      }
      return 'progress.green';
    }
    if (category === 'Pola Makan Sehat') {
      if (percent <= 57) {
        return 'progress.red';
      }
      if (percent <= 83) {
        return 'progress.yellow';
      }
      return 'progress.green';
    }
    if (category === 'Level Aktivitas Fisik') {
      if (percent <= 65) {
        return 'progress.red';
      }
      if (percent <= 85) {
        return 'progress.yellow';
      }
      return 'progress.green';
    }
    if (category === 'Kesiapan Hidup Sehat') {
      if (percent <= 35) {
        return 'progress.red';
      }
      if (percent <= 55) {
        return 'progress.yellow';
      }
      return 'progress.green';
    }
    if (category === 'Gejala Gangguan Makan (eating disorder)') {
      if (percent <= 15) {
        return 'progress.green';
      }
      if (percent <= 30) {
        return 'progress.yellow';
      }
      return 'progress.red';
    }
    if (category === 'Pemahaman Gizi dan Hidup Sehat') {
      if (percent <= 55) {
        return 'progress.red';
      }
      if (percent <= 80) {
        return 'progress.yellow';
      }
      return 'progress.green';
    }
  }

  return (
    <Container>
      <Box bg="dietela.primary" w="100%" h="40px" />
      <Box
        py={7}
        w="100%"
      >
        <Image
          src={logoDietela}
          h={{
            lg: '36px',
            md: '32px',
            base: '24px'
          }}
          alt="Logo Dietela"
        />
        <Divider borderColor="#C4C4C4" mt="20px" />
      </Box>
      <Box>
        <Text
          fontSize="14px"
          fontWeight="700"
          fontFamily="montserrat"
          mb="10px"
        >
          Hallo FIRST NAME
        </Text>
        <Text
          fontSize="12px"
          fontWeight="500"
          fontFamily="montserrat"
          mb="10px"
        >
          Sebagai Perempuan dengan usia antara 24 - 28 tahun,
        </Text>
        <Text
          fontSize="12px"
          fontWeight="400"
          fontFamily="montserrat"
        >
          Kamu perlu terus perhatikan pola makan sepanjang kehamilanmu.
          Setiap makanan yang kamu konsumsi akan menjadi sumber zat gizi
          bagi janin dalam kandunganmu.
          Pastikan kamu melakukan gerak aktif juga ya selama hamil.
        </Text>
        <Text
          fontSize="12px"
          fontWeight="400"
          fontFamily="montserrat"
          mb="24px"
        >
          Cari lah beragam sumber untuk mendukung pola hidup sehatmu selama hamil.
          Bisa mulai dengan artikel-artikel untuk ibu hamil, berkonsultasi dengan Ahli Gizi,
          atau pun mengikuti kelas khusus untuk ibu hamil.
          Terus kontrol rutin dengan dokter/bidan mu yah.
        </Text>
      </Box>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mb="24px"
      >
        <Box
          fontSize="12px"
          fontWeight="700"
          fontFamily="montserrat"
          bg="dietela.primary"
          textAlign="center"
          color="white"
          borderRadius="12px"
          padding="10px"
        >
          KESIAPANMU UNTUK CAPAI HIDUP LEBIH SEHAT
        </Box>
        <Box
          width="100%"
          borderRadius="30px"
          padding="10px"
          bg="dietela.background-primary-variant"
          mt="-20px"
          zIndex="-99"
          pt="20px"
        >
          <Grid
            templateRows={{
              base: 'repeat(3, 1fr)',
              md: 'repeat(2, 1fr)'
            }}
            templateColumns={{
              base: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)'
            }}
            gap={3}
            mt={6}
          >
            <GridItem>
              <VStack>
                <CircularProgress
                  size="100px"
                  value={83}
                  color={colorPercentage('Kondisi Personal', Math.round(83))}
                  trackColor="#878487"
                  capIsRound
                >
                  <CircularProgressLabel
                    fontSize="xl"
                    fontWeight="bold"
                  >
                    {Math.round(83.00)}
                    %
                  </CircularProgressLabel>
                </CircularProgress>
                <Text textAlign="center" fontSize="sm">
                  Kondisi Personal
                </Text>
              </VStack>
            </GridItem>
            <GridItem>
              <VStack>
                <CircularProgress
                  size="100px"
                  value={89}
                  color={colorPercentage('Pola Makan Sehat', Math.round(89))}
                  trackColor="#878487"
                  capIsRound
                >
                  <CircularProgressLabel
                    fontSize="xl"
                    fontWeight="bold"
                  >
                    {Math.round(89.00)}
                    %
                  </CircularProgressLabel>
                </CircularProgress>
                <Text textAlign="center" fontSize="sm">
                  Pola Makan Sehat
                </Text>
              </VStack>
            </GridItem>
            <GridItem>
              <VStack>
                <CircularProgress
                  size="100px"
                  value={10}
                  color={colorPercentage('Level Aktivitas Fisik', Math.round(10))}
                  trackColor="#878487"
                  capIsRound
                >
                  <CircularProgressLabel
                    fontSize="xl"
                    fontWeight="bold"
                  >
                    {Math.round(10.00)}
                    %
                  </CircularProgressLabel>
                </CircularProgress>
                <Text textAlign="center" fontSize="sm">
                  Level Aktivitas Fisik
                </Text>
              </VStack>
            </GridItem>
            <GridItem>
              <VStack>
                <CircularProgress
                  size="100px"
                  value={30}
                  color={colorPercentage('Kesiapan Hidup Sehat', Math.round(30))}
                  trackColor="#878487"
                  capIsRound
                >
                  <CircularProgressLabel
                    fontSize="xl"
                    fontWeight="bold"
                  >
                    {Math.round(30.00)}
                    %
                  </CircularProgressLabel>
                </CircularProgress>
                <Text textAlign="center" fontSize="sm">
                  Kesiapan Hidup Sehat
                </Text>
              </VStack>
            </GridItem>
            <GridItem>
              <VStack>
                <CircularProgress
                  size="100px"
                  value={0}
                  color={colorPercentage('Gejala Gangguan Makan (eating disorder)', Math.round(0))}
                  trackColor="#878487"
                  capIsRound
                >
                  <CircularProgressLabel
                    fontSize="xl"
                    fontWeight="bold"
                  >
                    {Math.round(0.00)}
                    %
                  </CircularProgressLabel>
                </CircularProgress>
                <Text textAlign="center" fontSize="sm">
                  Gejala Gangguan Makan (eating disorder)
                </Text>
              </VStack>
            </GridItem>
            <GridItem>
              <VStack>
                <CircularProgress
                  size="100px"
                  value={88}
                  color={colorPercentage('Pemahaman Gizi dan Hidup Sehat', Math.round(88))}
                  trackColor="#878487"
                  capIsRound
                >
                  <CircularProgressLabel
                    fontSize="xl"
                    fontWeight="bold"
                  >
                    {Math.round(88.00)}
                    %
                  </CircularProgressLabel>
                </CircularProgress>
                <Text textAlign="center" fontSize="sm">
                  Pemahaman Gizi dan Hidup Sehat
                </Text>
              </VStack>
            </GridItem>
          </Grid>
        </Box>
      </Flex>
      <Flex
        mb="24px"
      >
        <Box
          borderRadius="0 10px 10px 0"
          bg="dietela.background-secondary"
          padding="10px 10px"
        >
          <Text
            fontSize="12px"
            fontWeight="700"
            fontFamily="roboto"
          >
            Indeks Massa Tubuh: 19.29 kg/m2
          </Text>
        </Box>
      </Flex>
      <Flex
        w="100%"
        justifyContent="end"
        alignItems="start"
        mb="24px"
      >
        <Box
          borderRadius="10px 0px 0px 10px"
          bg="dietela.background-secondary"
          padding="10px 10px"
          w="90%"
        >
          <Text
            as="span"
            fontSize="12px"
            fontWeight="700"
            fontFamily="roboto"
          >
            Status Gizi:
          </Text>
          <Text
            as="span"
            fontSize="12px"
            fontWeight="400"
            fontFamily="roboto"
            ml="8px"
          >
            Normal
          </Text>
          <Text
            fontSize="12px"
            fontWeight="400"
            fontFamily="roboto"
          >
            “Status gizi yang ditandai dengan Indeks Massa Tubuh (IMT) adalah
            tanda fisik paling awal untuk mengetahui risiko masalah kesehatan
            dari gizi dan makanan.”
          </Text>
        </Box>
      </Flex>
      <Flex
        flexDirection="column"
      >
        <Box
          borderRadius="12px 12px 0 0"
          padding="10px 10px"
          border="2px solid #008C81"
          ml="7px"
          maxW="fit-content"
        >
          <Text
            fontSize="12px"
            fontWeight="700"
            fontFamily="roboto"
          >
            Program Untukmu
          </Text>
        </Box>
        <Box
          padding="20px 40px 40px 40px"
          bg="dietela.primary"
          mt="-2px"
        >
          <Text
            textAlign="center"
            fontSize="12px"
            fontWeight="400"
            fontFamily="roboto"
            color="white"
          >
            Berdasarkan kondisi dan kebiasaanmu saat ini,
            kamu membutuhkan program One-Time Consultation 7 Hari
            untuk dapat mencapai kebiasaan yang sehatmu.
          </Text>
        </Box>
      </Flex>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        w="100%"
        mt="-24px"
        mb="24px"
      >
        <Box
          width="70%"
          border="solid 1px #000000"
          borderRadius="12px"
          bg="white"
        >
          <Box
            borderRadius="12px 12px 0 0"
            textAlign="center"
            p="12px 12px"
          >
            <Text
              color="dietela.primary"
            >
              Clinicare Starter 30 hari
            </Text>
          </Box>
          <Box
            bg="dietela.primary"
            px="40px"
            py="10px"
            textAlign="center"
          >
            <Text
              fontSize="12px"
              fontWeight="500"
              fontFamily="montserrat"
              color="white"
            >
              Clinicare Diet Program adalah solusi untuk kondisi khusus yang membutuhkan
              perbaikan pola makan secara holistik.
            </Text>
            <Text
              as="span"
              fontSize="12px"
              fontWeight="500"
              fontFamily="montserrat"
              color="white"
            >
              Cocok untuk kamu yang
            </Text>
            <Text
              as="span"
              color="dietela.secondary"
              fontSize="12px"
              fontWeight="500"
              fontFamily="montserrat"
              ml="3px"
            >
              ingin memulai
              gaya hidup sehat namun tetap bisa makan enak.
            </Text>
            <Text
              color="white"
              fontSize="12px"
              fontWeight="500"
              fontFamily="montserrat"
              ml="3px"
            >
              Bonus tubuh mulai terasa lebih fit (bugar).
            </Text>
          </Box>
          <Box
            textAlign="center"
            py="12px"
          >
            <Button
              bg="dietela.secondary"
              _active={{
                bg: 'dietela.secondary-variant'
              }}
              _hover={{
                bg: 'dietela.secondary-variant'
              }}
              color="black"
              borderRadius="full"
              as="u"
            >
              Daftar Sekarang
            </Button>
          </Box>
        </Box>
      </Flex>
      <Flex
        mb="24px"
        w="100%"
        flexDirection="column"
      >
        <Box
          borderRadius="0 10px 10px 0"
          bg="dietela.primary"
          padding="10px 10px"
          w="50%"
        >
          <Text
            fontSize="12px"
            fontWeight="700"
            fontFamily="roboto"
            textAlign="end"
            color="white"
          >
            Pola Makan
          </Text>
        </Box>
        <Box
          borderRadius="0 10px 10px 0"
          bg="dietela.background-tertiary"
          padding="10px 10px"
          w="90%"
        >
          <Text
            fontSize="12px"
            fontWeight="400"
            fontFamily="roboto"
            pl="40px"
          >
            Kamu sudah punya pola makan yang sehat.
            Pertahankan konsumsi buah dan sayur minimal 4-5 porsi dalam sehari
            dan konsumsi makanan yang beraneka ragam. Terus batasi konsumsi gorengan,
            makanan kemasan dan makanan cepat saji.
            Pola makan yang seperti ini akan menurunkan risikomu terkena penyakit
            gaya hidup dan tubuhmu akan lebih sering terasa bugar.
          </Text>
        </Box>
      </Flex>
      <Flex
        mb="24px"
        w="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="end"
      >
        <Box
          borderRadius="10px 0px 0px 10px"
          bg="dietela.primary"
          padding="10px 10px"
          w="50%"
        >
          <Text
            fontSize="12px"
            fontWeight="700"
            fontFamily="roboto"
            color="white"
          >
            Aktivitas Fisik
          </Text>
        </Box>
        <Box
          borderRadius="10px 0px 0px 10px"
          bg="dietela.background-tertiary"
          padding="10px 10px"
          w="90%"
        >
          <Text
            fontSize="12px"
            fontWeight="400"
            fontFamily="roboto"
          >
            Level keaktifan kamu diatas kebanyakan orang lho. Pertahankan ya!
            Oia, seimbangkan keaktifan kamu dengan pola makan yang sehat yah.
            Dengan itu kamu akan memiliki tubuh yang bugar, sehat, dan memiliki
            risiko yang rendah terhadap penyakit gaya hidup. Kesehatan jantungmu
            juga akan terus terjaga. Seimbangkan juga antara gerakan kardio,
            gerakan penguatan otot, dan gerakan yang melatih kelenturan tubuhmu yah :)
          </Text>
        </Box>
      </Flex>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mb="24px"
      >
        <Box
          fontSize="14px"
          fontWeight="700"
          fontFamily="roboto"
          bg="dietela.primary"
          textAlign="center"
          color="white"
          borderRadius="12px 12px 0 0"
          px="15px"
          py="2px"
        >
          Coba Layanan Dietela
        </Box>
        <Box
          width="100%"
          borderRadius="50px 0px"
          padding="10px"
          bg="dietela.background-primary-variant"
          zIndex="-99"
          pt="20px"
        >
          <Text
            textAlign="center"
            fontSize="12px"
            fontWeight="400"
            fontFamily="roboto"
          >
            Mau coba layanan dietela selama 7 hari secara
          </Text>
          <Text
            textAlign="center"
            fontSize="16px"
            fontWeight="500"
            fontFamily="roboto"
          >
            gratis?
          </Text>
          <Box
            textAlign="center"
            py="12px"
          >
            <Button
              bg="dietela.secondary"
              _active={{
                bg: 'dietela.secondary-variant'
              }}
              _hover={{
                bg: 'dietela.secondary-variant'
              }}
              color="black"
              borderRadius="full"
              as="u"
            >
              Coba Sekarang
            </Button>
          </Box>
        </Box>
      </Flex>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mb="24px"
      >
        <Box
          fontSize="14px"
          fontWeight="700"
          fontFamily="roboto"
          bg="dietela.primary"
          textAlign="center"
          color="white"
          borderRadius="12px 12px 0 0"
          px="15px"
          py="2px"
        >
          Rekomendasi Ekslusif
        </Box>
        <Box
          width="100%"
          borderRadius="50px 0px"
          padding="10px"
          bg="dietela.background-primary-variant"
          zIndex="-99"
          pt="20px"
        >
          <Text
            textAlign="center"
            fontSize="12px"
            fontWeight="400"
            fontFamily="roboto"
            px="50px"
          >
            Masih ragu dalam memilih program? konsultasikan lebih lanjut tentang
            target dan masalah kamu, Nutrition Representative kami akan membantu
            merekomendasikan program yang sesuai dengan kondisimu.
          </Text>
          <Box
            textAlign="center"
            py="12px"
          >
            <Button
              bg="dietela.secondary"
              _active={{
                bg: 'dietela.secondary-variant'
              }}
              _hover={{
                bg: 'dietela.secondary-variant'
              }}
              color="black"
              borderRadius="full"
              as="u"
            >
              Mulai Konsultasi
            </Button>
          </Box>
        </Box>
      </Flex>
    </Container>
  );
}

export default Email;
