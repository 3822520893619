import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import {
  Box,
  Button,
  Container,
  Heading,
  Input,
  Skeleton,
  Text,
  Center,
  VStack
} from '@chakra-ui/react';
import BaseLayout from 'components/layout/BaseLayout';
import { setUserId } from 'reducer/userReducer';
import { useNavigate } from 'react-router-dom';
import { setArr, setDisableNext, setScore, setLastQuestion } from 'reducer/questionReducer';
import ProgressBar from 'components/common/ProgressBar';
import posthog from 'posthog-js';

function InputName() {
  posthog.capture('NAMA Question: Siapa Nama Kamu?', {
    page: 'Input Name'
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [value, setValue] = useState('');
  const disableNext = useSelector((state) => state.question.disableNext);

  const handleChange = (event) => {
    localStorage.setItem('user_name', event.target.value);
    if (event.target.value === '' || !event.target.value.trim()) {
      setValue('');
    } else {
      setValue(event.target.value);
    }
  };

  useEffect(() => {
    if (value === '' || !value.trim()) {
      if (localStorage.getItem('user_name')) {
        setValue(localStorage.getItem('user_name'));
      } else dispatch(setDisableNext(true));
    } else dispatch(setDisableNext(false));
  }, [value]);

  async function postName() {
    try {
      const res = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API}users`,
        data: {
          name: value
        }
      });
      if (res) {
        dispatch(setUserId(res?.data?.user_id));
        localStorage.setItem('user_id', res?.data?.user_id);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleNext = () => {
    if (value !== '') {
      postName();
      localStorage.setItem('position', 0);
      localStorage.removeItem('data');
      localStorage.removeItem('score');
      dispatch(setArr());
      dispatch(setScore());
      dispatch(setLastQuestion({
        position: 0
      }));
      navigate('/assessment');
    }
  };

  const isLoaded = 'true';

  return (
    <BaseLayout>
      <Box
        alignItems="center"
        minH="calc(100vh - 103px)"
        boxSizing="border-box"
      >
        <Container maxW="70ch">
          <VStack>
            <Skeleton isLoaded={isLoaded}>
              <Text fontFamily="roboto" fontWeight="700" fontSize="22px" color="dietela.primary">
                Yuk, kenalan!
              </Text>
              <Text align="center" fontFamily="roboto" fontWeight="500" fontSize="16px" color="dietela.primary">
                Pertanyaan 1/7
              </Text>
            </Skeleton>
            <Skeleton w="100%" isLoaded={isLoaded}>
              <ProgressBar
                totalPart={7}
                part={1}
              />
            </Skeleton>
          </VStack>
          <VStack alignItems="start" spacing={2}>
            <Heading
              fontSize={{
                base: '18px',
                md: '20px'
              }}
              pt={5}
              mt={2}
            >
              Siapa namamu?
            </Heading>
            <Input
              variant="flushed"
              textColor="black"
              borderColor="dietela.primary"
              placeholder="Type your answer here..."
              value={value}
              onChange={handleChange}
              pt={5}
            />
          </VStack>
          <VStack mt={12}>
            <Center>
              <Button
                  // eslint-disable-next-line react/jsx-boolean-value
                disabled={disableNext}
                alignSelf="center"
                className="next-button"
                px={6}
                borderRadius="full"
                bg="dietela.secondary"
                _active={{
                  bg: 'dietela.secondary-variant'
                }}
                _hover={{
                  bg: 'dietela.secondary-variant'
                }}
                onClick={handleNext}
                size={{
                  lg: 'md',
                  md: 'md',
                  base: 'sm'
                }}
              >
                Selanjutnya
              </Button>
            </Center>
          </VStack>
        </Container>
      </Box>
    </BaseLayout>
  );
}

export default InputName;
