import React from 'react';
import { Box, Image } from '@chakra-ui/react';

function CTAImage({ url, pb }) {
  return (
    <Box
      pb={pb}
      w="100%"
      justifyContent="center"
      alignItems="center"
      display="flex"
      maxH="fit-content"
    >
      <Image
        src={url}
        alt="NAMA CTA Image"
        minHeight="100px"
        w={{
          lg: '300px',
          md: '300px',
          base: '250px'
        }}
      />
    </Box>
  );
}

export default CTAImage;
