import React from 'react';
import { Box, Text } from '@chakra-ui/react';

function CTAText({ text, pb }) {
  return (
    <Box
      pb={pb}
      w="100%"
    >
      <Text
        fontSize={{
          lg: '16px',
          md: '16px',
          base: '12px'
        }}
        fontFamily="montserrat"
        fontWeight="normal"
        textAlign="center"
      >
        { text }
      </Text>
    </Box>
  );
}

export default CTAText;
