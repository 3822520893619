/* eslint-disable import/no-unresolved */
import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Pagination, Navigation } from 'swiper';
import { Flex } from '@chakra-ui/react';

import { customBackground } from 'style/theme';
import PhysicalActivityCard from 'components/Result/PhysicalActivityCard';
import ConditionTextCard from 'components/Result/ConditionTextCard';
import DietaryHabitCard from 'components/Result/DietaryHabitCard';
import EatingDisorderCard from 'components/Result/EatingDisorderCard';
import BmiStatusCard from 'components/Result/BmiStatusCard';
import NutritionStatusCard from 'components/Result/NutritionStatusCard';
import RecommendationCard from 'components/Result/RecommendationCard';
import { useDispatch } from 'react-redux';
import { setDisableNext } from 'reducer/questionReducer';
import ConditionChartCard from '../ConditionChartCard';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'style/carousel.css';

function ResultCarousel() {
  const dispatch = useDispatch();
  const data = [
    {
      id: 1,
      component: <ConditionTextCard />
    },
    {
      id: 8,
      component: <ConditionChartCard />
    },
    {
      id: 6,
      component: <NutritionStatusCard />
    },
    {
      id: 5,
      component: <BmiStatusCard />
    },
    {
      id: 2,
      component: <DietaryHabitCard />
    },
    {
      id: 3,
      component: <PhysicalActivityCard />
    },
    {
      id: 4,
      component: <EatingDisorderCard />
    },
    {
      id: 7,
      component: <RecommendationCard />
    }
  ];

  useEffect(() => {
    customBackground('#008C811A');
    return () => {
      document.body.style = '';
    };
  }, []);

  return (
    <Swiper
      slidesPerView={1}
      breakpoints={{
        320: {
          slidesPerView: 1,
          spaceBetween: 50
        },
        768: {
          slidesPerView: 1,
          spaceBetween: 0
        },
        1024: {
          slidesPerView: 1,
          spaceBetween: 50
        },
        1440: {
          slidesPerView: 1,
          spaceBetween: 50
        }
      }}
      modules={[Pagination, A11y, Navigation]}
      pagination={{
        clickable: true
      }}
      navigation
      centeredSlides
      grabCursor
      cssMode="true"
      onAfterInit={() => {
        dispatch(setDisableNext(true));
      }}
      onReachEnd={() => {
        dispatch(setDisableNext(false));
      }}
    >
      {data.map((item) =>
        (
          <SwiperSlide key={item.id}>
            <Flex justifyContent="center" alignItems="start" mb={10} w="auto">
              {item.component}
            </Flex>
          </SwiperSlide>
        ))}
    </Swiper>
  );
}

export default ResultCarousel;
