import React from 'react';
import { Box, useRadio } from '@chakra-ui/react';

function RangeCard(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box
      as="label"
    >
      <input {...input} />
      <Box
        {...checkbox}
        w={{
          base: '27px', sm: '35px', md: '50px', lg: '50px'
        }}
        h={{
          base: '27px', sm: '35px', md: '50px', lg: '50px'
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        bg={props.value === props.selected ? 'dietela.primary' : '#F2F2F2F2'}
        color={props.value === props.selected ? 'white' : 'black'}
        // _checked={{
        //   bg: 'dietela.primary',
        //   color: 'white'
        // }}
        borderColor="dietela.primary"
        _hover={{
          color: 'white',
          bg: 'dietela.primary'
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
}

export default RangeCard;
