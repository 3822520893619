import React from 'react';
import { Box, Text, VStack, OrderedList, ListItem } from '@chakra-ui/react';

function CTAListBox({ listBoxType, listData, mb }) {
  // console.log(listData);
  const listHead = listData.split('*')[0];
  let listComponents;
  if (listBoxType === 'list-box') {
    const listItems = listData.split('*')[1].split('|');
    listComponents = listItems.map(
      (liText) => <ListItem key={liText}>{liText}</ListItem>
    );
  } else if (listBoxType === 'list-box1') {
    const listItems = listData.split('*')[1].split('|');
    listComponents = listItems.map(
      (liText) => (
        <Box
          key={liText}
          mb="8px"
        >
          <Text
            as="span"
            fontWeight="bold"
            display="inline"
          >
            {liText.split('~')[0]}
            &nbsp;
          </Text>
          <Text
            as="span"
          >
            {liText.split('~')[1]}
          </Text>
        </Box>
      )
    );
  }

  return (
    <Box
      w="100%"
    >
      <VStack
        mb={mb}
      >
        <Box
          mb="-1.7rem"
          px="1.5rem"
          py="0.5rem"
          borderRadius={{
            lg: '12px',
            md: '12px',
            base: '8px'
          }}
          maxW="85%"
          bg="dietela.primary"
          zIndex="popover"
        >
          <Text
            color="white"
            fontSize={{
              lg: '18px',
              md: '18px',
              base: '14px'
            }}
            fontFamily="montserrat"
            fontWeight="bold"
          >
            {listHead}
          </Text>
        </Box>
        <Box
          borderWidth="2px"
          px="1rem"
          pt="2rem"
          pb="1rem"
          borderRadius={{
            lg: '12px',
            md: '12px',
            base: '8px'
          }}
          borderColor="dietela.primary"
          align="left"
          minW="90%"
          bg="white"
        >
          <OrderedList
            fontSize={{
              lg: '18px',
              md: '18px',
              base: '14px'
            }}
            fontFamily="montserrat"
          >
            {listComponents}
          </OrderedList>
        </Box>
      </VStack>
    </Box>
  );
}

export default CTAListBox;
