import { configureStore } from '@reduxjs/toolkit';
import finalResultReducer from 'reducer/finalResultReducer';
import progressBarReducer from 'reducer/progressBarReducer';
import questionReducer from 'reducer/questionReducer';
import userReducer from 'reducer/userReducer';

export default configureStore({
  reducer: {
    progressBar: progressBarReducer,
    question: questionReducer,
    finalResult: finalResultReducer,
    user: userReducer
  }
});
