import React, { useEffect, useState } from 'react';
import { FormControl, FormErrorMessage, Input } from '@chakra-ui/react';
import { setChoice, setDisableNext, setAnswers } from 'reducer/questionReducer';
import { setSendEmail } from 'reducer/finalResultReducer';
import { useSelector, useDispatch } from 'react-redux';
import { storeAnswer } from 'utils/localStorage';

function TextInput({ data }) {
  const dispatch = useDispatch();
  const currentQuestion = useSelector((state) =>
    state.question.data);

  let answer = '';

  if (localStorage.getItem('user_answers')) {
    if (JSON.parse(localStorage.getItem('user_answers')).some((answer) => answer.question_id === currentQuestion?.data?.id)) {
      answer = JSON.parse(localStorage.getItem('user_answers')).filter((answer) => answer.question_id === currentQuestion?.data?.id)[0].answer.options.form.email;
    }
  }

  const [value, setValue] = useState(answer);
  const [isError, setError] = useState(false);

  const handleChange = (event) => {
    if (event.target.value === '') {
      setValue('');
    } else {
      setValue(event.target.value);
    }
  };

  const mailFormat = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;

  useEffect(() => {
    if (data?.data?.question?.includes('email')) {
      if (value === '') {
        dispatch(setDisableNext(true));
        setError(false);
      } else if (!value.match(mailFormat) && value !== '') {
        setError(true);
        dispatch(setDisableNext(true));
      } else {
        setError(false);
        dispatch(setDisableNext(false));
      }

      const newObj = {
        question_type: 'form',
        userChoice: data?.data?.id,
        choiceScore: 0,
        options: {
          form: {
            email: value
          }
        }
      };
      dispatch(setChoice(newObj));
      dispatch(setSendEmail(true));
      // save answers to local storage
      const answerList = storeAnswer({
        question_id: currentQuestion?.data?.id,
        question_type: currentQuestion?.data?.question_type,
        answer: newObj
      });
      dispatch(setAnswers(answerList));
    }
  }, [value]);

  return (
    <FormControl isInvalid={isError}>
      <Input
        variant="flushed"
        textColor="black"
        borderColor="dietela.primary"
        placeholder="Type your answer here..."
        value={value}
        onChange={handleChange}
      />
      {isError
        && <FormErrorMessage fontWeight="800">Email format not valid</FormErrorMessage>}
    </FormControl>
  );
}

export default TextInput;
