import React from 'react';
import { HStack, Progress } from '@chakra-ui/react';
// import { CheckIcon } from '@chakra-ui/icons';

function ProgressBar({ totalPart, part }) {
  const progressValue = Math.floor((part / totalPart) * 100);

  // console.log(progressValue);
  return (
    <HStack width="full">
      <Progress
        value={progressValue}
        width="full"
        borderRadius={5}
        colorScheme="teal"
        bg="#C4C4C4"
      />
    </HStack>
  );
}

export default ProgressBar;
