import React, { useEffect, useState } from 'react';
import {
  Flex,
  Text,
  Center,
  useRadioGroup
} from '@chakra-ui/react';
import { setChoice, setDisableNext, setAnswers } from 'reducer/questionReducer';
import { useDispatch, useSelector } from 'react-redux';
import { storeAnswer } from 'utils/localStorage';
import RangeCard from './RangeCard';

function RangeInput({ data }) {
  const choice = [];
  const currentQuestion = useSelector((state) =>
    state.question.data);

  for (let i = 0; i < data?.data?.range_length; i += 1) {
    choice[i] = (i + 1).toString();
  }

  const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const [selected, setSelected] = useState(0);

  const setSelected1 = (item) => {
    const newObj = {
      question_type: 'range',
      userChoice: 0,
      choiceScore: 0,
      options: {
        range: {
          score: item
        }
      }
    };
    // dispatch(setChoice(newObj));
    setSelected(item);

    const answerList = storeAnswer({
      question_id: currentQuestion?.data?.id,
      question_type: currentQuestion?.data?.question_type,
      answer: {
        selected: item
      },
      choiceProps: newObj
    });
    dispatch(setAnswers(answerList));
  };

  useEffect(() => {
    // console.log(selected);

    const newObj = {
      question_type: 'range',
      userChoice: 0,
      choiceScore: 0,
      options: {
        range: {
          score: selected
        }
      }
    };
    dispatch(setChoice(newObj));

    if (selected === 0) {
      dispatch(setDisableNext(true));
      if (localStorage.getItem('user_answers')) {
        if (JSON.parse(localStorage.getItem('user_answers')).some((answer) => answer.question_id === currentQuestion?.data?.id)) {
          const { answer, choiceProps } = JSON.parse(localStorage.getItem('user_answers')).filter((answer) => answer.question_id === currentQuestion?.data?.id)[0];
          if (answer.selected) setSelected(answer.selected);
          if (choiceProps) dispatch(setChoice(choiceProps));
        }
      }
    } else dispatch(setDisableNext(false));
  }, [selected]);

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'range',
    onChange: setSelected1
  });

  const group = getRootProps();

  return (
    <Center>
      <Flex
        w={{
          base: '100%', lg: '572px'
        }}
        flexDir="column"
      >
        <Flex
          {...group}
          justifyContent="space-between"
          alignItems="center"
        >
          {choice.map((value) => (
            <RangeCard
              key={value}
              {...getRadioProps({
                value
              })}
              selected={selected}
            >
              {value}
            </RangeCard>
          ))}
        </Flex>
        <Flex mt={2} flexDir="row" justifyContent="space-between" fontSize="sm">
          <Text>Sangat Tidak Sehat</Text>
          <Text>Sangat Sehat</Text>
        </Flex>
      </Flex>
    </Center>
  );
}

export default RangeInput;
