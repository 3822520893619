/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const questionSlicer = createSlice({
  name: 'question',
  initialState: {
    data: {
    },
    choice: {
    },
    disableNext: true,
    lastNavigate: {
    },
    DisPrev: true,
    arrBack: [],
    lastQuestion: {
      position: 0
    },
    scoreBack: [],
    condition: 0,
    answers: [],
    choiceGroup: []
  },

  reducers: {
    setData: (state, action) => {
      if (action.payload === []) {
        state.data = [];
      } else state.data = action.payload;
    },
    setChoice: (state, action) => {
      if (!action.payload) {
        state.choice = {
        };
      } else state.choice = action.payload;
    },
    setDisableNext: (state, action) => {
      if (action.payload) {
        state.disableNext = true;
      } else state.disableNext = action.payload;
    },
    setLastNavigate: (state, action) => {
      if (!action.payload) {
        state.lastNavigate = {
        };
      } else state.lastNavigate = action.payload;
    },
    Prev: (state, action) => {
      if (action.payload) {
        state.disablePrev = true;
      } else state.disablePrev = action.payload;
    },
    setLastQuestion: (state, action) => {
      if (!action.payload) {
        state.lastQuestion = {
        };
      } else state.lastQuestion = action.payload;
    },
    setArrBack: (state, action) => {
      if (!action.payload) {
        state.arrBack = [
        ];
      } else state.arrBack.push(action.payload);
    },
    removeArrBack: (state) => {
      state.arrBack.pop();
    },
    removeScoreBack: (state) => {
      state.scoreBack.pop();
    },
    setScoreBack: (state, action) => {
      state.scoreBack.push(action.payload);
    },
    setCondition: (state, action) => {
      if (!action.payload) {
        state.condition = 0;
      } else state.condition = action.payload;
    },
    setArr: (state, action) => {
      if (!action.payload) {
        state.arrBack = [
        ];
      } else state.arrBack = action.payload;
    },
    setScore: (state, action) => {
      if (!action.payload) {
        state.scoreBack = [
        ];
      } else state.scoreBack = action.payload;
    },
    setAnswers: (state, action) => {
      if (!action.payload) {
        state.answers = [];
      } else {
        state.answers = action.payload;
      }
    },
    setChoiceGroup: (state, action) => {
      if (!action.payload) {
        state.choiceGroup = [
        ];
      } else state.choiceGroup = action.payload;
    }
  }
});

export const { setData, setChoice, setDisableNext, setLastNavigate, Prev,
  setLastQuestion, setArrBack, removeArrBack, removeScoreBack,
  setScoreBack, setCondition, setArr, setScore, setAnswers,
  setChoiceGroup } = questionSlicer.actions;
export default questionSlicer.reducer;
