import React from 'react';
import { Container, Flex } from '@chakra-ui/react';

function ResultCardLayout({ children }) {
  return (
    <Container
      maxW={{
        base: '90%',
        lg: '800px',
        md: '90%'
      }}
      pb="36px"
    >
      <Flex
        h={{
          md: '600px',
          lg: '600px'
        }}
        // maxW="90%"
        bg="white"
        overflow="hidden"
        borderTopLeftRadius={48}
        borderTopRightRadius={16}
        borderBottomRightRadius={48}
        borderBottomLeftRadius={16}
      >
        {children}
      </Flex>
    </Container>
  );
}

export default ResultCardLayout;
