import React, { useEffect } from 'react';
import { Container, VStack } from '@chakra-ui/react';
import CTAH2 from 'components/CTA/CTAH2';
import CTAH1 from 'components/CTA/CTAH1';
import CTAText from 'components/CTA/CTAText';
import CTAImage from 'components/CTA/CTAImage';
import CTATextBox from 'components/CTA/CTATextBox';
import CTAListBox from 'components/CTA/CTAListBox';
import CTATable from 'components/CTA/CTATable';
import CTAIcon from 'components/CTA/CTAIcon';
import { customBackground } from 'style/theme';
import { useDispatch } from 'react-redux';
import { setChoice } from 'reducer/questionReducer';
import posthog from 'posthog-js';

function CTAPage({ CTAComponentsData, CTA }) {
  const dispatch = useDispatch();

  useEffect(() => {
    customBackground('#008C811A');
    return () => {
      document.body.style = '';
    };
  }, []);

  useEffect(() => {
    const newObj = {
      question_type: 'cta',
      userChoice: 0,
      choiceScore: 0
    };
    dispatch(setChoice(newObj));
  }, []);

  // console.log(CTA);

  const listComponents = CTAComponentsData.map((cta) => {
    let component;
    if (cta.component_type === 'h1') {
      component = <CTAH1 key={cta.data} text={cta.data} pb="10px" />;
    } else if (cta.component_type === 'h2') {
      component = <CTAH2 key={cta.data} text={cta.data} pb="10px" />;
    } else if (cta.component_type === 'image') {
      component = <CTAImage key={cta.data} url={cta.data} pb="10px" />;
    } else if (cta.component_type === 'list-box' || cta.component_type === 'list-box1') {
      component = <CTAListBox key={cta.data} listBoxType={cta.component_type} listData={cta.data} mb="10px" />;
    } else if (cta.component_type === 'table') {
      component = <CTATable key={cta.data} tableData={cta.data} mb="10px" />;
    } else if (cta.component_type === 'text') {
      component = <CTAText key={cta.data} text={cta.data} pb="10px" />;
    } else if (cta.component_type === 'text-box') {
      component = <CTATextBox key={cta.data} text={cta.data} mb="24px" />;
    } else if (cta.component_type === 'icon') {
      component = <CTAIcon key={cta.data} url={cta.data} pb="10px" />;
    }
    return component;
  });

  posthog.capture(`NAMA CTA: ${CTA}`, {
    page: 'CTA Page'
  });

  return (
    <Container
      maxW="xl"
      display="flex"
      flexDir="column"
      justifyContent="center"
      alignItems="center"
    >
      <VStack textAlign="center">
        { listComponents }
      </VStack>
    </Container>
  );
}

export default CTAPage;
