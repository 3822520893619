import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

function CTATextBox({ textHeading, text, textAlign, mb }) {
  return (
    <Box w="100%">
      <Box
        mb={mb}
        px="1rem"
        py="1rem"
        borderRadius={{
          lg: '16px',
          md: '16px',
          base: '8px'
        }}
        bg="dietela.background-secondary"
        textAlign={textAlign}
        w="100%"
      >
        <Heading
          fontSize={{
            lg: '16px',
            md: '16px',
            base: '12px'
          }}
          fontFamily="montserrat"
          fontWeight="bold"
        >
          {textHeading}
        </Heading>
        <Text
          fontSize={{
            lg: '16px',
            md: '16px',
            base: '12px'
          }}
          fontFamily="montserrat"
          fontWeight="medium"
        >
          { text }
        </Text>
      </Box>
    </Box>
  );
}

export default CTATextBox;
