import React, { useEffect, useState } from 'react';
import { Box, RadioGroup, Text, Radio, Image, Stack, VisuallyHidden, Center } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { setChoice, setDisableNext, setCondition } from 'reducer/questionReducer';

function RadioButtonInput({ data }) {
  const [value, setValue] = useState('');
  const dispatch = useDispatch();

  // const choice = useSelector((state) =>
  //   state.question.choice);

  const handleChange = (item) => {
    // console.log(item);
    const newObj = {
      question_type: 'radio',
      userChoice: item.id,
      choiceScore: item.score
    };
    dispatch(setChoice(newObj));
    if (item.id === '33' || item.id === 33) {
      dispatch(setCondition(1));
    } else if (item.id === '34' || item.id === 34) {
      dispatch(setCondition(2));
    } else if (item.id === '35' || item.id === 35) {
      dispatch(setCondition(3));
    }
  };

  useEffect(() => {
    if (value === '') dispatch(setDisableNext(true));
    else dispatch(setDisableNext(false));
  }, [value]);

  const parseOption = (text) => {
    const option = text.split('.');
    if (option.length === 1) {
      return option[0];
    }
    return option[1];
  };

  // Set Selected Radio Option Answered from Local Storage
  let userSex;
  if (localStorage.getItem('user_answers')) {
    if (JSON.parse(localStorage.getItem('user_answers')).some(
      (answer) => answer.question_id === 1
    )) {
      userSex = JSON.parse(localStorage.getItem('user_answers')).filter((answer) => answer.question_id === 1)[0].answer.option;
    }
  }

  return (
    <RadioGroup onChange={setValue} value={value} colorScheme="white">
      <Center>
        <Stack direction="row">
          {data.data.Options.map((item) =>
            (
              <Box
                key={item.id}
              >
                {item.option === 'Kehamilan' && userSex === 'Laki laki'
                  ? (
                    ''
                  ) : (
                    <Box
                      borderRadius="8px"
                      cursor="pointer"
                      color={
                        value.includes(item.option)
                          ? 'white'
                          : 'white'
                        }
                      bg={
                        value.includes(item.option)
                          ? 'dietela.primary'
                          : '#C4C4C4'
                        }
                      _hover={{
                        color: 'white',
                        bg: 'dietela.primary'
                      }}
                      align="center"
                      value={item.option}
                      onClick={() => {
                        handleChange(item);
                        setValue(item.option);
                      }}
                      h={{
                        lg: '250px',
                        md: '200px',
                        base: '150px'
                      }}
                      w={{
                        lg: '200px',
                        md: '150px',
                        base: '100px'
                      }}
                      pt="2rem"
                      pb="2rem"
                    >
                      <Image
                        src={item.image}
                        alt="Button Image"
                        h={{
                          lg: '150px',
                          md: '100px',
                          base: '60px'
                        }}
                      />
                      <Text
                        as="h1"
                        align="center"
                        fontSize={{
                          lg: '20px',
                          md: '16px',
                          base: '14px'
                        }}
                        mb="1rem"
                        mt="2"
                      >
                        {parseOption(item.option)}
                      </Text>
                      <VisuallyHidden>
                        <Radio
                          w="100%"
                          px={4}
                          py={3}
                          value={item.option}
                          onChange={() => handleChange(item)}
                        >
                          {/* <Image
                            src="https://ik.imagekit.io/dietela/opening_screening.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1672636007088"
                            alt="Opening Screen"
                            w={{
                              lg: '50%',
                              md: '70%',
                              sm: '100%'
                            }}
                          />
                          {parseOption(item.option)} */}
                        </Radio>
                      </VisuallyHidden>
                    </Box>
                  )}
              </Box>
            ))}
        </Stack>
      </Center>
    </RadioGroup>
  );
}

export default RadioButtonInput;
