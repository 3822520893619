import { createSlice } from '@reduxjs/toolkit';

export const finalResultSlicer = createSlice({
  name: 'finalResult',
  initialState: {
    data: {
    },
    sendEmail: false
  },

  reducers: {
    insertResultData: (state, action) => {
      state.data = action.payload;
    },
    setSendEmail: (state, action) => {
      // console.log(action.payload);
      if (!action.payload) {
        state.sendEmail = false;
      }
      state.sendEmail = action.payload;
    }
  }
});

export const { insertResultData, setSendEmail } = finalResultSlicer.actions;
export default finalResultSlicer.reducer;
