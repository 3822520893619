import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { Routes, Route } from 'react-router-dom';

import StartPage from 'views/StartPage';
import InstructionPage from 'views/InstructionPage';
import QuestionPage from 'views/QuestionPage';
import theme from 'style/theme';
import ProgramPricingPage from 'views/ProgramPricingPage';
import FinalResultPage from 'views/FinalResultPage';
import InputName from 'views/QuestionPage/inputName';
import Email from 'views/Email';
import Unsubscribe from 'views/Unsubscribe/Form';
import UnsubscribeSuccess from 'views/Unsubscribe/Success';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Routes>
        <Route path="/" element={<StartPage />} />
        <Route path="instruction" element={<InstructionPage />} />
        <Route path="name" element={<InputName />} />
        <Route path="assessment" element={<QuestionPage />} />
        <Route path="result" element={<FinalResultPage />} />
        <Route path="program-recommendation" element={<ProgramPricingPage />} />
        <Route path="email" element={<Email />} />
        <Route path="unsubscribe" element={<Unsubscribe />} />
        <Route path="unsubscribe-success" element={<UnsubscribeSuccess />} />
      </Routes>
    </ChakraProvider>
  );
}

export default App;
