import React, { useEffect } from 'react';
import { Box, Image, Container, Text } from '@chakra-ui/react';
import { customBackground } from 'style/theme';

function UnsubscribeSuccess() {
  useEffect(() => {
    customBackground('#008C811A');
    return () => {
      document.body.style = '';
    };
  }, []);

  return (
    <Container>
      <Box
        py={7}
        w="100%"
      >
        <Image
          src="https://ik.imagekit.io/dietela/unsubscribe-success.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1672633867915"
          alt="Logo Dietela"
          m="0 auto"
        />
      </Box>
      <Box>
        <Text
          fontSize="22px"
          fontWeight="700"
          fontFamily="montserrat"
          my="10px"
          textAlign="center"
        >
          Pengingat berhasil diberhentikan.
        </Text>
        <Text
          fontSize="18px"
          fontWeight="500"
          fontFamily="montserrat"
          my="10px"
          textAlign="center"
        >
          Kalau suatu hari kami mau mengisi lagi pemeriksaan
          kondisi hidup sehat di dietela, ingat dan simpan link ini ya
        </Text>
        <Text
          fontSize="18px"
          fontWeight="500"
          fontFamily="montserrat"
          textAlign="center"
          color="teal"
        >
          <a href="https://nama.dietela.id/" rel="noreferrer" target="_blank">
            https://nama.dietela.id/
          </a>
        </Text>
      </Box>
    </Container>
  );
}

export default UnsubscribeSuccess;
