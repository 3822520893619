import React from 'react';
import { Box } from '@chakra-ui/react';

import Navbar from 'components/common/Navbar';

function BaseLayout({ children }) {
  return (
    <Box
      px={{
        base: '4',
        md: '10',
        lg: '20'
      }}
    >
      <Navbar />
      {children}
    </Box>
  );
}

export default BaseLayout;
